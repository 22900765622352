import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import {
  Typography, Box, Grid, Paper, Button, Divider, List, ListItem,
  ListItemText, ListItemIcon, CircularProgress, Card, CardContent
} from '@mui/material';
import { AttachMoney, Event, Message, Person } from '@mui/icons-material';
import api from '../../services/api';

const Dashboard = () => {
  const { currentUser } = useAuth();
  const [tithes, setTithes] = useState([]);
  const [messages, setMessages] = useState([]);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Carregar dados em paralelo
        const [tithesResponse, messagesResponse, eventsResponse] = await Promise.all([
          api.get('/api/tithes/my-tithes'),
          api.get('/api/messages'),
          api.get('/api/events')
        ]);
        
        setTithes(tithesResponse.data);
        setMessages(messagesResponse.data);
        
        // Filtrar apenas eventos futuros
        const now = new Date();
        const upcomingEvents = eventsResponse.data
          .filter(event => new Date(event.startDate) > now)
          .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
          .slice(0, 5); // Apenas os próximos 5 eventos
        
        setEvents(upcomingEvents);
      } catch (error) {
        console.error('Erro ao carregar dados do dashboard:', error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchData();
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          Olá, {currentUser.name}!
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Bem-vindo à sua área de membro. Aqui você pode gerenciar seu dízimo, ver mensagens e eventos.
        </Typography>
      </Box>

      <Grid container spacing={3}>
        {/* Card de informações do usuário */}
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 3, height: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Person sx={{ fontSize: 40, color: 'primary.main', mr: 2 }} />
              <Typography variant="h5">Seu Perfil</Typography>
            </Box>
            <Divider sx={{ mb: 2 }} />
            <Typography variant="body1" gutterBottom>
              <strong>Nome:</strong> {currentUser.name}
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>Email:</strong> {currentUser.email}
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>Telefone:</strong> {currentUser.phone || 'Não informado'}
            </Typography>
            <Button 
              variant="outlined" 
              component={RouterLink} 
              to="/area-membro/perfil"
              sx={{ mt: 2 }}
            >
              Editar Perfil
            </Button>
          </Paper>
        </Grid>

        {/* Card de dízimos */}
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 3, height: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <AttachMoney sx={{ fontSize: 40, color: 'primary.main', mr: 2 }} />
              <Typography variant="h5">Dízimos</Typography>
            </Box>
            <Divider sx={{ mb: 2 }} />
            {tithes.length > 0 ? (
              <>
                <Typography variant="subtitle1" gutterBottom>
                  Últimas contribuições:
                </Typography>
                <List>
                  {tithes.slice(0, 3).map((tithe) => (
                    <ListItem key={tithe._id} disablePadding>
                      <ListItemText
                        primary={`R$ ${tithe.amount.toFixed(2)}`}
                        secondary={`${new Date(tithe.createdAt).toLocaleDateString()} - ${tithe.frequency === 'monthly' ? 'Mensal' : 'Único'}`}
                      />
                    </ListItem>
                  ))}
                </List>
              </>
            ) : (
              <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
                Você ainda não tem contribuições registradas.
              </Typography>
            )}
            <Button 
              variant="contained" 
              component={RouterLink} 
              to="/area-membro/dizimo"
              sx={{ mt: 2 }}
            >
              Contribuir com Dízimo
            </Button>
          </Paper>
        </Grid>

        {/* Card de mensagens */}
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 3, height: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Message sx={{ fontSize: 40, color: 'primary.main', mr: 2 }} />
              <Typography variant="h5">Mensagens</Typography>
            </Box>
            <Divider sx={{ mb: 2 }} />
            {messages.length > 0 ? (
              <List>
                {messages.slice(0, 3).map((message) => (
                  <ListItem key={message._id} disablePadding>
                    <ListItemText
                      primary={message.title}
                      secondary={new Date(message.sentAt).toLocaleDateString()}
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
                Nenhuma mensagem disponível no momento.
              </Typography>
            )}
            <Button 
              variant="outlined" 
              component={RouterLink} 
              to="/area-membro/mensagens"
              sx={{ mt: 2 }}
            >
              Ver Todas as Mensagens
            </Button>
          </Paper>
        </Grid>

        {/* Próximos eventos */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Event sx={{ fontSize: 40, color: 'primary.main', mr: 2 }} />
                <Typography variant="h5">Próximos Eventos</Typography>
              </Box>
              <Divider sx={{ mb: 2 }} />
              {events.length > 0 ? (
                <Grid container spacing={2}>
                  {events.map((event) => (
                    <Grid item xs={12} sm={6} md={4} key={event._id}>
                      <Paper sx={{ p: 2 }}>
                        <Typography variant="h6" gutterBottom>
                          {event.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                          <strong>Data:</strong> {new Date(event.startDate).toLocaleDateString()}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                          <strong>Local:</strong> {event.location}
                        </Typography>
                        <Typography variant="body2" noWrap>
                          {event.description}
                        </Typography>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Typography variant="body1" color="text.secondary">
                  Não há eventos programados para os próximos dias.
                </Typography>
              )}
              <Button 
                variant="outlined" 
                component={RouterLink} 
                to="/calendario"
                sx={{ mt: 3 }}
              >
                Ver Calendário Completo
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
