import React, { useState, useEffect } from 'react';
import {
  Typography, Box, Paper, Divider, List, ListItem, ListItemText,
  CircularProgress, Alert, Dialog, DialogTitle, DialogContent, IconButton,
  DialogActions, Button, Chip
} from '@mui/material';
import { Message, Close } from '@mui/icons-material';
import api from '../../services/api';

const Messages = () => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        setLoading(true);
        const response = await api.get('/api/messages');
        
        // Ordenar mensagens por data (mais recentes primeiro)
        const sortedMessages = response.data.sort((a, b) => 
          new Date(b.sentAt) - new Date(a.sentAt)
        );
        
        setMessages(sortedMessages);
      } catch (err) {
        console.error('Erro ao carregar mensagens:', err);
        setError('Não foi possível carregar as mensagens. Tente novamente mais tarde.');
      } finally {
        setLoading(false);
      }
    };

    fetchMessages();
  }, []);

  const handleOpenMessage = async (messageId) => {
    try {
      const response = await api.get(`/api/messages/${messageId}`);
      setSelectedMessage(response.data);
      setDialogOpen(true);
    } catch (err) {
      console.error('Erro ao carregar detalhes da mensagem:', err);
      setError('Não foi possível carregar os detalhes da mensagem.');
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedMessage(null);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Mensagens
      </Typography>
      <Typography variant="body1" paragraph>
        Aqui você pode ver todas as mensagens e palavras enviadas pela igreja.
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      <Paper sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Message sx={{ fontSize: 28, color: 'primary.main', mr: 2 }} />
          <Typography variant="h5">Minhas Mensagens</Typography>
        </Box>
        <Divider sx={{ mb: 2 }} />

        {messages.length > 0 ? (
          <List>
            {messages.map((message) => (
              <ListItem 
                key={message._id} 
                alignItems="flex-start" 
                divider
                button
                onClick={() => handleOpenMessage(message._id)}
              >
                <ListItemText
                  primary={
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Typography variant="subtitle1" component="span">
                        {message.title}
                      </Typography>
                      <Chip 
                        label={new Date(message.sentAt).toLocaleDateString()} 
                        size="small" 
                        color={message.type === 'broadcast' ? 'primary' : 'secondary'}
                        variant="outlined"
                      />
                    </Box>
                  }
                  secondary={
                    <>
                      <Typography variant="body2" color="text.secondary" component="span">
                        De: {message.author?.name || 'Sistema'}
                      </Typography>
                      <br />
                      <Typography 
                        variant="body2" 
                        color="text.secondary" 
                        component="span"
                        sx={{ 
                          display: '-webkit-box',
                          overflow: 'hidden',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2
                        }}
                      >
                        {message.content}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'center', py: 4 }}>
            Não há mensagens disponíveis no momento.
          </Typography>
        )}
      </Paper>

      {/* Diálogo para visualizar mensagem completa */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        {selectedMessage && (
          <>
            <DialogTitle sx={{ pr: 6 }}>
              {selectedMessage.title}
              <IconButton
                aria-label="close"
                onClick={handleCloseDialog}
                sx={{ position: 'absolute', right: 8, top: 8 }}
              >
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle2" gutterBottom>
                  De: {selectedMessage.author?.name || 'Sistema'}
                </Typography>
                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                  Data: {new Date(selectedMessage.sentAt).toLocaleDateString()}
                </Typography>
                <Chip 
                  label={selectedMessage.type === 'broadcast' ? 'Mensagem para todos' : 'Mensagem pessoal'} 
                  size="small" 
                  color={selectedMessage.type === 'broadcast' ? 'primary' : 'secondary'}
                  sx={{ mt: 1 }}
                />
              </Box>
              <Divider sx={{ mb: 2 }} />
              <Typography variant="body1" paragraph sx={{ whiteSpace: 'pre-line' }}>
                {selectedMessage.content}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog}>Fechar</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Box>
  );
};

export default Messages;
