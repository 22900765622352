import React, { useState, useEffect } from 'react';
import {
  Typography, Box, Paper, TextField, Button, Grid,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Dialog, DialogTitle, DialogContent, DialogActions, IconButton,
  Alert, CircularProgress, FormControl, InputLabel, Select, MenuItem,
  FormControlLabel, Switch, Divider, Chip, Tooltip, InputAdornment,
  Card, CardMedia
} from '@mui/material';
import {
  Add, Edit, Delete, Event, Close, Save, Search, CalendarMonth,
  Image, Share, Facebook, WhatsApp, Twitter, Instagram
} from '@mui/icons-material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ptBR from 'date-fns/locale/pt-BR';
import api from '../../services/api';

const EventManagement = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    location: '',
    startDate: new Date(),
    endDate: null,
    category: 'worship',
    isRecurring: false,
    recurrencePattern: 'none',
    imageUrl: ''
  });
  const [dragActive, setDragActive] = useState(false);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      setLoading(true);
      const response = await api.get('/api/events');
      
      // Ordenar por data (próximos eventos primeiro)
      const sortedEvents = response.data.sort((a, b) => 
        new Date(a.startDate) - new Date(b.startDate)
      );
      
      setEvents(sortedEvents);
    } catch (err) {
      console.error('Erro ao carregar eventos:', err);
      setError('Não foi possível carregar a lista de eventos.');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    
    if (name === 'isRecurring') {
      setFormData(prev => ({
        ...prev,
        [name]: checked,
        recurrencePattern: checked ? 'weekly' : 'none'
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleDateChange = (name, value) => {
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleOpenDialog = (event = null) => {
    if (event) {
      // Modo edição
      setFormData({
        title: event.title,
        description: event.description,
        location: event.location,
        startDate: new Date(event.startDate),
        endDate: new Date(event.endDate),
        category: event.category,
        isRecurring: event.isRecurring,
        recurrencePattern: event.recurrencePattern
      });
      setSelectedEvent(event);
    } else {
      // Modo criação
      setFormData({
        title: '',
        description: '',
        location: '',
        startDate: new Date(),
        endDate: null,
        category: 'worship',
        isRecurring: false,
        recurrencePattern: 'none',
        imageUrl: ''
      });
      setSelectedEvent(null);
    }
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setError('');
  };

  const handleOpenConfirmDialog = (event) => {
    setSelectedEvent(event);
    setConfirmDialogOpen(true);
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validação
    if (!formData.title || !formData.description || !formData.location) {
      return setError('Título, descrição e local são obrigatórios.');
    }
    
    if (formData.endDate && formData.startDate >= formData.endDate) {
      return setError('A data de término deve ser posterior à data de início.');
    }
    
    try {
      setError('');
      
      // Preparar dados para envio
      const eventData = {
        title: formData.title,
        description: formData.description,
        location: formData.location,
        startDate: formData.startDate,
        endDate: formData.endDate,
        category: formData.category,
        isRecurring: formData.isRecurring,
        recurrencePattern: formData.isRecurring ? formData.recurrencePattern : 'none',
        imageUrl: formData.imageUrl,
        // Preparar links de compartilhamento (serão preenchidos no backend)
        shareLinks: {
          facebook: '',
          whatsapp: '',
          twitter: '',
          instagram: ''
        }
      };
      
      if (selectedEvent) {
        // Atualizar evento existente
        await api.put(`/api/events/${selectedEvent._id}`, eventData);
        setSuccess('Evento atualizado com sucesso!');
      } else {
        // Criar novo evento
        await api.post('/api/events', eventData);
        setSuccess('Evento criado com sucesso!');
      }
      
      // Fechar diálogo e atualizar lista
      setDialogOpen(false);
      fetchEvents();
      
      // Limpar mensagem de sucesso após 3 segundos
      setTimeout(() => {
        setSuccess('');
      }, 3000);
    } catch (err) {
      setError(err.response?.data?.error || 'Erro ao processar a requisição.');
    }
  };

  const handleDeleteEvent = async () => {
    try {
      await api.delete(`/api/events/${selectedEvent._id}`);
      
      setConfirmDialogOpen(false);
      setSuccess('Evento removido com sucesso!');
      fetchEvents();
      
      // Limpar mensagem de sucesso após 3 segundos
      setTimeout(() => {
        setSuccess('');
      }, 3000);
    } catch (err) {
      setError(err.response?.data?.error || 'Erro ao remover evento.');
      setConfirmDialogOpen(false);
    }
  };

  // Filtrar eventos de acordo com a busca
  const filteredEvents = events.filter(event => {
    const searchLower = searchTerm.toLowerCase();
    return (
      event.title.toLowerCase().includes(searchLower) ||
      event.description.toLowerCase().includes(searchLower) ||
      event.location.toLowerCase().includes(searchLower)
    );
  });

  // Função para renderizar o chip de categoria
  const getCategoryChip = (category) => {
    let color = 'default';
    let label = 'Outro';
    
    switch (category) {
      case 'worship':
        color = 'primary';
        label = 'Culto';
        break;
      case 'prayer':
        color = 'info';
        label = 'Oração';
        break;
      case 'study':
        color = 'success';
        label = 'Estudo';
        break;
      case 'community':
        color = 'warning';
        label = 'Comunidade';
        break;
      default:
        color = 'default';
        label = 'Outro';
    }
    
    return <Chip label={label} color={color} size="small" />;
  };

  if (loading && events.length === 0) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Gestão de Eventos
      </Typography>
      <Typography variant="body1" paragraph>
        Gerencie os eventos da igreja, adicione novos eventos ou edite os existentes.
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      {success && (
        <Alert severity="success" sx={{ mb: 3 }}>
          {success}
        </Alert>
      )}

      <Paper sx={{ p: 3, mb: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <TextField
            label="Buscar eventos"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ width: 300 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => handleOpenDialog()}
          >
            Novo Evento
          </Button>
        </Box>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Evento</TableCell>
                <TableCell>Data</TableCell>
                <TableCell>Local</TableCell>
                <TableCell>Categoria</TableCell>
                <TableCell>Recorrência</TableCell>
                <TableCell align="center">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredEvents.length > 0 ? (
                filteredEvents.map((event) => (
                  <TableRow key={event._id}>
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {event.imageUrl ? (
                          <Card sx={{ width: 48, height: 48, mr: 2, borderRadius: 1, overflow: 'hidden' }}>
                            <CardMedia
                              component="img"
                              height="48"
                              image={event.imageUrl}
                              alt={event.title}
                              sx={{ objectFit: 'cover' }}
                            />
                          </Card>
                        ) : (
                          <Box 
                            sx={{ 
                              width: 48, 
                              height: 48, 
                              mr: 2, 
                              borderRadius: 1, 
                              bgcolor: 'action.selected',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center' 
                            }}
                          >
                            <Event color="action" />
                          </Box>
                        )}
                        <Typography variant="body2">{event.title}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <CalendarMonth sx={{ mr: 1, color: 'text.secondary', fontSize: 16 }} />
                        <Box>
                          <Typography variant="body2">
                            {new Date(event.startDate).toLocaleDateString()}
                          </Typography>
                          <Typography variant="caption" color="text.secondary">
                            {new Date(event.startDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                            {event.endDate && (
                              <>
                                {' - '}
                                {new Date(event.endDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                              </>
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>{event.location}</TableCell>
                    <TableCell>{getCategoryChip(event.category)}</TableCell>
                    <TableCell>
                      {event.isRecurring ? (
                        <Chip 
                          label={
                            event.recurrencePattern === 'daily' ? 'Diário' :
                            event.recurrencePattern === 'weekly' ? 'Semanal' :
                            event.recurrencePattern === 'monthly' ? 'Mensal' :
                            event.recurrencePattern === 'yearly' ? 'Anual' :
                            'Recorrente'
                          } 
                          color="secondary"
                          size="small"
                          variant="outlined"
                        />
                      ) : (
                        <Typography variant="body2" color="text.secondary">Não</Typography>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title="Editar">
                        <IconButton onClick={() => handleOpenDialog(event)} color="primary">
                          <Edit />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Excluir">
                        <IconButton onClick={() => handleOpenConfirmDialog(event)} color="error">
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    {searchTerm ? 'Nenhum evento encontrado para esta busca.' : 'Nenhum evento cadastrado ainda.'}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* Diálogo para adicionar/editar evento */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>
          {selectedEvent ? 'Editar Evento' : 'Novo Evento'}
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
            <Box component="form" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Título do Evento"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                </Grid>
                
                <Grid item xs={12}>
                  <TextField
                    label="Descrição"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    fullWidth
                    required
                    multiline
                    rows={3}
                  />
                </Grid>
                
                <Grid item xs={12}>
                  <TextField
                    label="Local"
                    name="location"
                    value={formData.location}
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <DateTimePicker
                    label="Data e Hora de Início"
                    value={formData.startDate}
                    onChange={(newValue) => handleDateChange('startDate', newValue)}
                    renderInput={(params) => <TextField {...params} fullWidth required />}
                  />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <DateTimePicker
                    label="Data e Hora de Término (opcional)"
                    value={formData.endDate}
                    onChange={(newValue) => handleDateChange('endDate', newValue)}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </Grid>
                
                {/* Campo para upload de imagem */}
                <Grid item xs={12}>
                  <Box sx={{ border: '1px dashed #ccc', p: 3, borderRadius: 1, textAlign: 'center', mb: 2 }}>
                    <input
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="image-upload"
                      type="file"
                      onChange={async (e) => {
                        if (e.target.files && e.target.files[0]) {
                          try {
                            setLoading(true);
                            
                            // Criar FormData para envio
                            const formData = new FormData();
                            formData.append('image', e.target.files[0]);
                            
                            // Enviar imagem
                            const response = await api.post('/api/events/upload-image', formData, {
                              headers: {
                                'Content-Type': 'multipart/form-data'
                              }
                            });
                            
                            // Atualizar URL da imagem no formulário
                            setFormData(prev => ({
                              ...prev,
                              imageUrl: response.data.imageUrl
                            }));
                            
                            setLoading(false);
                          } catch (error) {
                            setError('Erro ao fazer upload da imagem: ' + (error.response?.data?.error || error.message));
                            setLoading(false);
                          }
                        }
                      }}
                    />
                    <label htmlFor="image-upload">
                      <Button
                        variant="outlined"
                        component="span"
                        startIcon={<Image />}
                      >
                        Selecionar Imagem
                      </Button>
                    </label>
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                      Arraste e solte uma imagem aqui ou clique para selecionar
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      Formatos aceitos: JPG, PNG, GIF (máx. 5MB)
                    </Typography>
                  </Box>
                  
                  {loading && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                      <CircularProgress size={24} />
                    </Box>
                  )}
                  
                  <TextField
                    label="URL da Imagem"
                    name="imageUrl"
                    value={formData.imageUrl}
                    onChange={handleChange}
                    fullWidth
                    placeholder="https://exemplo.com/imagem.jpg"
                    helperText="A URL será preenchida automaticamente após o upload, ou você pode inserir manualmente"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Image />
                        </InputAdornment>
                      ),
                      readOnly: false,
                    }}
                  />
                </Grid>
                
                {/* Preview da imagem (se fornecida) */}
                {formData.imageUrl && (
                  <Grid item xs={12}>
                    <Card sx={{ mb: 2 }}>
                      <CardMedia
                        component="img"
                        height="200"
                        image={formData.imageUrl}
                        alt="Preview da imagem"
                        sx={{ objectFit: 'cover' }}
                        onError={(e) => {
                          e.target.onerror = null;
                          setError('URL da imagem inválida. Por favor, verifique o endereço.');
                          setFormData({...formData, imageUrl: ''});
                        }}
                      />
                    </Card>
                  </Grid>
                )}
                
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Categoria</InputLabel>
                    <Select
                      value={formData.category}
                      name="category"
                      label="Categoria"
                      onChange={handleChange}
                    >
                      <MenuItem value="worship">Culto</MenuItem>
                      <MenuItem value="prayer">Oração</MenuItem>
                      <MenuItem value="study">Estudo Bíblico</MenuItem>
                      <MenuItem value="community">Comunidade</MenuItem>
                      <MenuItem value="other">Outro</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.isRecurring}
                        onChange={handleChange}
                        name="isRecurring"
                        color="primary"
                      />
                    }
                    label="Evento Recorrente"
                  />
                </Grid>
                
                {formData.isRecurring && (
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Padrão de Recorrência</InputLabel>
                      <Select
                        value={formData.recurrencePattern}
                        name="recurrencePattern"
                        label="Padrão de Recorrência"
                        onChange={handleChange}
                      >
                        <MenuItem value="daily">Diário</MenuItem>
                        <MenuItem value="weekly">Semanal</MenuItem>
                        <MenuItem value="monthly">Mensal</MenuItem>
                        <MenuItem value="yearly">Anual</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                
                {/* Seção para compartilhamento em redes sociais */}
                {selectedEvent && (
                  <Grid item xs={12}>
                    <Paper 
                      elevation={0}
                      sx={{ 
                        p: 2, 
                        mt: 2, 
                        bgcolor: 'rgba(0, 0, 0, 0.02)',
                        borderRadius: 2
                      }}
                    >
                      <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                        <Share sx={{ mr: 1 }} /> 
                        Compartilhar Evento
                      </Typography>
                      
                      <Typography variant="body2" color="text.secondary" paragraph>
                        Os visitantes poderão compartilhar este evento nas redes sociais.
                      </Typography>
                      
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                        <Chip
                          icon={<Facebook />}
                          label="Facebook"
                          color="primary"
                          variant="outlined"
                          size="small"
                        />
                        <Chip
                          icon={<WhatsApp />}
                          label="WhatsApp"
                          color="success"
                          variant="outlined"
                          size="small"
                        />
                        <Chip
                          icon={<Twitter />}
                          label="Twitter"
                          color="info"
                          variant="outlined"
                          size="small"
                        />
                      </Box>
                    </Paper>
                  </Grid>
                )}
              </Grid>
            </Box>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancelar</Button>
          <Button 
            variant="contained" 
            onClick={handleSubmit}
            startIcon={<Save />}
          >
            {selectedEvent ? 'Atualizar' : 'Criar Evento'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Diálogo de confirmação de exclusão */}
      <Dialog open={confirmDialogOpen} onClose={handleCloseConfirmDialog}>
        <DialogTitle>Confirmar Exclusão</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Tem certeza que deseja excluir o evento "{selectedEvent?.title}"?
          </Typography>
          <Typography variant="body2" color="error" sx={{ mt: 1 }}>
            Esta ação não pode ser desfeita.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog}>Cancelar</Button>
          <Button onClick={handleDeleteEvent} color="error">
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EventManagement;
