import React, { useState, useEffect } from 'react';
import {
  Typography, Box, Paper, TextField, Button, Grid,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Dialog, DialogTitle, DialogContent, DialogActions, IconButton,
  Alert, CircularProgress, Divider, Chip, InputAdornment, Tooltip,
  FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import {
  Add, Edit, Delete, Message, Close, Send, Search, WhatsApp, Save
} from '@mui/icons-material';
import api from '../../services/api';

const MessageManagement = () => {
  const [messages, setMessages] = useState([]);
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    type: 'broadcast',
    recipients: [],
    sentVia: ['app'],
    scheduledFor: null
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        // Carregar mensagens e membros em paralelo
        const [messagesResponse, membersResponse] = await Promise.all([
          api.get('/api/messages'),
          api.get('/api/members')
        ]);
        
        // Ordenar mensagens por data (mais recentes primeiro)
        const sortedMessages = messagesResponse.data.sort((a, b) => 
          new Date(b.sentAt) - new Date(a.sentAt)
        );
        
        setMessages(sortedMessages);
        setMembers(membersResponse.data);
      } catch (err) {
        console.error('Erro ao carregar dados:', err);
        setError('Não foi possível carregar as mensagens.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleRecipientsChange = (event) => {
    setFormData(prev => ({
      ...prev,
      recipients: event.target.value
    }));
  };

  const handleSentViaChange = (event) => {
    setFormData(prev => ({
      ...prev,
      sentVia: event.target.value
    }));
  };

  const handleOpenDialog = (message = null) => {
    if (message) {
      // Modo edição
      setFormData({
        title: message.title,
        content: message.content,
        type: message.type,
        recipients: message.recipients || [],
        sentVia: message.sentVia || ['app'],
        scheduledFor: message.scheduledFor || null
      });
      setSelectedMessage(message);
    } else {
      // Modo criação
      setFormData({
        title: '',
        content: '',
        type: 'broadcast',
        recipients: [],
        sentVia: ['app'],
        scheduledFor: null
      });
      setSelectedMessage(null);
    }
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setError('');
  };

  const handleOpenConfirmDialog = (message) => {
    setSelectedMessage(message);
    setConfirmDialogOpen(true);
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validação
    if (!formData.title || !formData.content) {
      return setError('Título e conteúdo são obrigatórios.');
    }
    
    if (formData.type === 'personal' && (!formData.recipients || formData.recipients.length === 0)) {
      return setError('Selecione pelo menos um destinatário para mensagens pessoais.');
    }
    
    try {
      setError('');
      
      // Preparar dados para envio
      const messageData = {
        title: formData.title,
        content: formData.content,
        type: formData.type,
        sentVia: formData.sentVia,
        scheduledFor: formData.scheduledFor
      };
      
      // Adicionar destinatários apenas para mensagens pessoais
      if (formData.type === 'personal') {
        messageData.recipients = formData.recipients;
      }
      
      if (selectedMessage) {
        // Atualizar mensagem existente
        await api.put(`/api/messages/${selectedMessage._id}`, messageData);
        setSuccess('Mensagem atualizada com sucesso!');
      } else {
        // Criar nova mensagem
        await api.post('/api/messages', messageData);
        setSuccess('Mensagem enviada com sucesso!');
      }
      
      // Fechar diálogo e atualizar lista
      setDialogOpen(false);
      
      // Recarregar mensagens
      const response = await api.get('/api/messages');
      const sortedMessages = response.data.sort((a, b) => 
        new Date(b.sentAt) - new Date(a.sentAt)
      );
      setMessages(sortedMessages);
      
      // Limpar mensagem de sucesso após 3 segundos
      setTimeout(() => {
        setSuccess('');
      }, 3000);
    } catch (err) {
      setError(err.response?.data?.error || 'Erro ao processar a mensagem.');
    }
  };

  const handleDeleteMessage = async () => {
    try {
      await api.delete(`/api/messages/${selectedMessage._id}`);
      
      setConfirmDialogOpen(false);
      setSuccess('Mensagem excluída com sucesso!');
      
      // Atualizar lista de mensagens
      setMessages(prev => prev.filter(message => message._id !== selectedMessage._id));
      
      // Limpar mensagem de sucesso após 3 segundos
      setTimeout(() => {
        setSuccess('');
      }, 3000);
    } catch (err) {
      setError(err.response?.data?.error || 'Erro ao excluir mensagem.');
      setConfirmDialogOpen(false);
    }
  };

  // Filtrar mensagens de acordo com a busca
  const filteredMessages = messages.filter(message => {
    const searchLower = searchTerm.toLowerCase();
    return (
      (message.title && message.title.toLowerCase().includes(searchLower)) ||
      (message.content && message.content.toLowerCase().includes(searchLower))
    );
  });

  if (loading && messages.length === 0) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Gestão de Mensagens
      </Typography>
      <Typography variant="body1" paragraph>
        Envie mensagens e avisos para os membros da igreja.
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      {success && (
        <Alert severity="success" sx={{ mb: 3 }}>
          {success}
        </Alert>
      )}

      <Paper sx={{ p: 3, mb: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <TextField
            label="Buscar mensagens"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ width: 300 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => handleOpenDialog()}
          >
            Nova Mensagem
          </Button>
        </Box>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Título</TableCell>
                <TableCell>Data de Envio</TableCell>
                <TableCell>Tipo</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Via</TableCell>
                <TableCell align="center">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredMessages.length > 0 ? (
                filteredMessages.map((message) => (
                  <TableRow key={message._id}>
                    <TableCell>{message.title}</TableCell>
                    <TableCell>
                      {new Date(message.sentAt).toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      <Chip 
                        label={message.type === 'broadcast' ? 'Para todos' : 'Pessoal'} 
                        color={message.type === 'broadcast' ? 'primary' : 'secondary'}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <Chip 
                        label={
                          message.status === 'draft' ? 'Rascunho' :
                          message.status === 'scheduled' ? 'Agendada' :
                          message.status === 'sent' ? 'Enviada' : 'Falhou'
                        } 
                        color={
                          message.status === 'sent' ? 'success' :
                          message.status === 'scheduled' ? 'info' :
                          message.status === 'draft' ? 'default' : 'error'
                        }
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      {message.sentVia?.map(via => (
                        <Chip 
                          key={via}
                          label={
                            via === 'app' ? 'App' :
                            via === 'email' ? 'Email' : 'WhatsApp'
                          }
                          size="small"
                          icon={
                            via === 'whatsapp' ? <WhatsApp fontSize="small" /> : undefined
                          }
                          sx={{ mr: 0.5 }}
                          variant="outlined"
                        />
                      ))}
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title="Editar">
                        <IconButton onClick={() => handleOpenDialog(message)} color="primary">
                          <Edit />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Excluir">
                        <IconButton onClick={() => handleOpenConfirmDialog(message)} color="error">
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    {searchTerm ? 'Nenhuma mensagem encontrada para esta busca.' : 'Nenhuma mensagem enviada ainda.'}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* Diálogo para adicionar/editar mensagem */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>
          {selectedMessage ? 'Editar Mensagem' : 'Nova Mensagem'}
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box component="form" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Título da Mensagem"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              
              <Grid item xs={12}>
                <TextField
                  label="Conteúdo da Mensagem"
                  name="content"
                  value={formData.content}
                  onChange={handleChange}
                  fullWidth
                  required
                  multiline
                  rows={6}
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Tipo de Mensagem</InputLabel>
                  <Select
                    name="type"
                    value={formData.type}
                    onChange={handleChange}
                    label="Tipo de Mensagem"
                  >
                    <MenuItem value="broadcast">Para Todos (Broadcast)</MenuItem>
                    <MenuItem value="personal">Pessoal (Destinatários Específicos)</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Enviar Via</InputLabel>
                  <Select
                    name="sentVia"
                    multiple
                    value={formData.sentVia}
                    onChange={handleSentViaChange}
                    label="Enviar Via"
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip 
                            key={value} 
                            label={
                              value === 'app' ? 'App' :
                              value === 'email' ? 'Email' : 'WhatsApp'
                            } 
                            size="small" 
                          />
                        ))}
                      </Box>
                    )}
                  >
                    <MenuItem value="app">App</MenuItem>
                    <MenuItem value="whatsapp">WhatsApp</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              
              {formData.type === 'personal' && (
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Destinatários</InputLabel>
                    <Select
                      multiple
                      value={formData.recipients}
                      onChange={handleRecipientsChange}
                      label="Destinatários"
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip 
                              key={value} 
                              label={members.find(m => m._id === value)?.name || value} 
                              size="small" 
                            />
                          ))}
                        </Box>
                      )}
                    >
                      {members.map((member) => (
                        <MenuItem key={member._id} value={member._id}>
                          {member.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancelar</Button>
          <Button 
            variant="contained" 
            onClick={handleSubmit}
            startIcon={<Send />}
          >
            {selectedMessage ? 'Atualizar' : 'Enviar Mensagem'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Diálogo de confirmação de exclusão */}
      <Dialog open={confirmDialogOpen} onClose={handleCloseConfirmDialog}>
        <DialogTitle>Confirmar Exclusão</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Tem certeza que deseja excluir a mensagem "{selectedMessage?.title}"?
          </Typography>
          <Typography variant="body2" color="error" sx={{ mt: 1 }}>
            Esta ação não pode ser desfeita.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog}>Cancelar</Button>
          <Button onClick={handleDeleteMessage} color="error">
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MessageManagement;
