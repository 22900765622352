import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import {
  Typography, Box, Paper, TextField, Button, Grid, 
  Avatar, Alert, CircularProgress, Divider
} from '@mui/material';
import { Save, Person } from '@mui/icons-material';

const Profile = () => {
  const { currentUser, updateProfile } = useAuth();
  
  const [formData, setFormData] = useState({
    name: currentUser?.name || '',
    email: currentUser?.email || '',
    phone: currentUser?.phone || '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validação para mudança de senha
    if (formData.newPassword || formData.confirmPassword) {
      if (!formData.currentPassword) {
        return setError('Informe a senha atual para alterar a senha.');
      }
      
      if (formData.newPassword !== formData.confirmPassword) {
        return setError('As novas senhas não coincidem.');
      }
      
      if (formData.newPassword.length < 6) {
        return setError('A nova senha deve ter pelo menos 6 caracteres.');
      }
    }
    
    try {
      setLoading(true);
      setError('');
      setSuccess('');
      
      // Preparar dados para atualização
      const updateData = {
        name: formData.name,
        phone: formData.phone
      };
      
      // Se estiver alterando a senha, inclua os campos de senha
      if (formData.currentPassword && formData.newPassword) {
        updateData.currentPassword = formData.currentPassword;
        updateData.newPassword = formData.newPassword;
      }
      
      await updateProfile(updateData);
      
      // Limpar campos de senha
      setFormData(prev => ({
        ...prev,
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      }));
      
      setSuccess('Perfil atualizado com sucesso!');
    } catch (err) {
      setError(err.message || 'Erro ao atualizar perfil. Tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Meu Perfil
      </Typography>
      <Typography variant="body1" paragraph>
        Atualize suas informações pessoais e senha.
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      {success && (
        <Alert severity="success" sx={{ mb: 3 }}>
          {success}
        </Alert>
      )}

      <Paper sx={{ p: 3, maxWidth: 800, mx: 'auto' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
          <Avatar sx={{ width: 60, height: 60, bgcolor: 'primary.main', mr: 2 }}>
            <Person sx={{ fontSize: 40 }} />
          </Avatar>
          <Typography variant="h5">
            Informações Pessoais
          </Typography>
        </Box>

        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Nome Completo"
                name="name"
                value={formData.name}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email"
                name="email"
                value={formData.email}
                disabled
                fullWidth
                helperText="O email não pode ser alterado"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Telefone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                fullWidth
                helperText="Formato: DDD + número (ex: 11999998888)"
              />
            </Grid>
          </Grid>

          <Divider sx={{ my: 4 }} />
          
          <Typography variant="h6" gutterBottom>
            Alteração de Senha
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            Preencha apenas se desejar alterar sua senha
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                type="password"
                label="Senha Atual"
                name="currentPassword"
                value={formData.currentPassword}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="password"
                label="Nova Senha"
                name="newPassword"
                value={formData.newPassword}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="password"
                label="Confirmar Nova Senha"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
          </Grid>

          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="submit"
              variant="contained"
              startIcon={<Save />}
              disabled={loading}
            >
              {loading ? 'Salvando...' : 'Salvar Alterações'}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default Profile;
