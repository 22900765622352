import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import { CssBaseline, Box, Typography, CircularProgress } from '@mui/material';

// Layouts
import AdminLayout from './layouts/AdminLayout';
import MemberLayout from './layouts/MemberLayout';
import PublicLayout from './layouts/PublicLayout';

// Páginas públicas
import Home from './pages/public/Home';
import Login from './pages/public/Login';
import Register from './pages/public/Register';
import AdminSetup from './pages/public/AdminSetup';
import Calendar from './pages/public/Calendar';
import PublicDonation from './pages/public/PublicDonation';

// Páginas do membro
import MemberDashboard from './pages/member/Dashboard';
import MemberProfile from './pages/member/Profile';
import Tithe from './pages/member/Tithe';
import MemberMessages from './pages/member/Messages';

// Páginas do admin
import AdminDashboard from './pages/admin/Dashboard';
import MemberManagement from './pages/admin/MemberManagement';
import TitheManagement from './pages/admin/TitheManagement';
import EventManagement from './pages/admin/EventManagement';
import MessageManagement from './pages/admin/MessageManagement';
import WhatsappDashboard from './pages/admin/WhatsappDashboard';
import CommunicationSettings from './pages/admin/CommunicationSettings';

// Rotas protegidas para admin e membro
const AdminRoute = ({ children }) => {
  const { currentUser, loading, token } = useAuth();
  
  if (loading) {
    console.log('AdminRoute - Loading:', { loading, token, currentUser });
    return (
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        height: '100vh'
      }}>
        <CircularProgress />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Carregando painel administrativo...
        </Typography>
      </Box>
    );
  }
  
  // Verificar se temos um token e um usuário válido com role de admin
  if (!token || !currentUser || currentUser.role !== 'admin') {
    console.log('Acesso admin negado:', { hasToken: !!token, hasUser: !!currentUser, role: currentUser?.role });
    return <Navigate to="/login" replace />;
  }
  
  return children;
};

const MemberRoute = ({ children }) => {
  const { currentUser, loading, token } = useAuth();
  
  if (loading) {
    console.log('MemberRoute - Loading:', { loading, token, currentUser });
    return (
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        height: '100vh'
      }}>
        <CircularProgress />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Carregando área do membro...
        </Typography>
      </Box>
    );
  }
  
  if (!token || !currentUser) {
    console.log('Acesso membro negado:', { hasToken: !!token, hasUser: !!currentUser });
    return <Navigate to="/login" replace />;
  }
  
  return children;
};

function App() {
  return (
    <>
      <CssBaseline />
      <Routes>
        {/* Rotas públicas */}
        <Route path="/" element={<PublicLayout />}>
          <Route index element={<Home />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="register/:churchSlug" element={<Register />} />
          <Route path="admin-setup/:churchSlug" element={<AdminSetup />} />
          <Route path="calendario" element={<Calendar />} />
          <Route path="doar/:churchSlug" element={<PublicDonation />} />
        </Route>
        
        {/* Rotas de membro */}
        <Route path="/area-membro" element={
          <MemberRoute>
            <MemberLayout />
          </MemberRoute>
        }>
          <Route index element={<MemberDashboard />} />
          <Route path="perfil" element={<MemberProfile />} />
          <Route path="dizimo" element={<Tithe />} />
          <Route path="mensagens" element={<MemberMessages />} />
        </Route>
        
        {/* Rotas de admin */}
        <Route path="/admin" element={
          <AdminRoute>
            <AdminLayout />
          </AdminRoute>
        }>
          <Route index element={<AdminDashboard />} />
          <Route path="membros" element={<MemberManagement />} />
          <Route path="dizimos" element={<TitheManagement />} />
          <Route path="eventos" element={<EventManagement />} />
          <Route path="mensagens" element={<MessageManagement />} />
          <Route path="whatsapp" element={<WhatsappDashboard />} />
          <Route path="comunicacoes" element={<CommunicationSettings />} />
        </Route>
        
        {/* Rota para páginas não encontradas */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
}

export default App;
