import React, { useState, useEffect } from 'react';
import { Calendar as BigCalendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import ptBR from 'date-fns/locale/pt-BR';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { 
  Box, Typography, Paper, CircularProgress, Dialog, 
  DialogContent, DialogActions, Button, Card, CardMedia, 
  CardContent, Grid, Avatar, Chip, IconButton, useTheme,
  useMediaQuery, Tab, Tabs, Divider, List, ListItem, 
  ListItemIcon, ListItemText
} from '@mui/material';
import { 
  Facebook, WhatsApp, Twitter, Instagram, LocationOn, 
  CalendarToday, AccessTime, Share, Close, Event, 
  ViewAgenda, ViewDay, ViewModule, Category 
} from '@mui/icons-material';
import api from '../../services/api';

const locales = {
  'pt-BR': ptBR,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

// Componente para cartão de evento
const EventCard = ({ event, onClick }) => {
  const theme = useTheme();

  // Define cores por categoria
  const getCategoryColor = (category) => {
    switch (category) {
      case 'worship': return theme.palette.primary.main;
      case 'prayer': return theme.palette.info.main;
      case 'study': return theme.palette.success.main;
      case 'community': return theme.palette.warning.main;
      default: return theme.palette.grey[500];
    }
  };

  // Define título da categoria
  const getCategoryTitle = (category) => {
    switch (category) {
      case 'worship': return 'Culto';
      case 'prayer': return 'Oração';
      case 'study': return 'Estudo';
      case 'community': return 'Comunidade';
      default: return 'Outro';
    }
  };

  const categoryColor = getCategoryColor(event.category);
  const categoryTitle = getCategoryTitle(event.category);

  // Verificar se é um evento futuro
  const isFutureEvent = new Date(event.start) > new Date();

  // Usar imagem de fundo padrão se o evento não tiver imagem
  const defaultImages = {
    worship: 'https://images.unsplash.com/photo-1519677584237-752f8853252e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=640',
    prayer: 'https://images.unsplash.com/photo-1476231682828-37e571bc172f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=640',
    study: 'https://images.unsplash.com/photo-1508857662573-e78193d55857?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=640',
    community: 'https://images.unsplash.com/photo-1511632765486-a01980e01a18?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=640',
    other: 'https://images.unsplash.com/photo-1579684385127-1ef15d508118?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=640'
  };

  const imageUrl = event.allData.imageUrl || defaultImages[event.category] || defaultImages.other;

  return (
    <Card 
      onClick={() => onClick(event)} 
      elevation={3} 
      sx={{ 
        cursor: 'pointer',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        transition: 'transform 0.2s ease-in-out',
        '&:hover': {
          transform: 'translateY(-4px)'
        },
        position: 'relative',
        borderRadius: 2,
        overflow: 'hidden'
      }}
    >
      {/* Faixa de categoria no topo */}
      <Box sx={{ 
        bgcolor: categoryColor, 
        height: 5, 
        width: '100%', 
        position: 'absolute',
        top: 0,
        zIndex: 1
      }} />
      
      <CardMedia
        component="img"
        height="140"
        image={imageUrl}
        alt={event.title}
        sx={{ objectFit: 'cover' }}
      />
      
      <CardContent sx={{ flexGrow: 1, pt: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1 }}>
          <Typography variant="h6" component="div" gutterBottom noWrap>
            {event.title}
          </Typography>
          
          <Chip 
            label={categoryTitle}
            size="small"
            sx={{ 
              bgcolor: `${categoryColor}20`, 
              color: categoryColor,
              fontWeight: 'medium',
              borderRadius: 1
            }}
          />
        </Box>
        
        <Box sx={{ display: 'flex', gap: 1, mb: 1, alignItems: 'center' }}>
          <CalendarToday fontSize="small" sx={{ color: 'text.secondary', fontSize: 16 }} />
          <Typography variant="body2" color="text.secondary">
            {format(new Date(event.start), 'd MMM', { locale: ptBR })}
          </Typography>
        </Box>
        
        <Box sx={{ display: 'flex', gap: 1, mb: 1, alignItems: 'center' }}>
          <AccessTime fontSize="small" sx={{ color: 'text.secondary', fontSize: 16 }} />
          <Typography variant="body2" color="text.secondary">
            {format(new Date(event.start), 'HH:mm')}
          </Typography>
        </Box>
        
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <LocationOn fontSize="small" sx={{ color: 'text.secondary', fontSize: 16 }} />
          <Typography variant="body2" color="text.secondary" noWrap>
            {event.location}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

// Componente de compartilhamento social
const ShareButtons = ({ event }) => {
  const handleShare = (platform) => {
    const url = window.location.href;
    const text = `${event.title} - ${format(new Date(event.start), 'PPP', { locale: ptBR })} às ${format(new Date(event.start), 'HH:mm')}`;
    
    let shareUrl = '';
    
    switch (platform) {
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${encodeURIComponent(text)}`;
        break;
      case 'twitter':
        shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(url)}`;
        break;
      case 'whatsapp':
        shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(text + ' ' + url)}`;
        break;
      default:
        return;
    }
    
    window.open(shareUrl, '_blank', 'width=600,height=400');
  };
  
  return (
    <Box sx={{ display: 'flex', gap: 1, mt: 2, justifyContent: 'center' }}>
      <IconButton onClick={() => handleShare('facebook')} aria-label="compartilhar no facebook" color="primary">
        <Facebook />
      </IconButton>
      <IconButton onClick={() => handleShare('whatsapp')} aria-label="compartilhar no whatsapp" color="success">
        <WhatsApp />
      </IconButton>
      <IconButton onClick={() => handleShare('twitter')} aria-label="compartilhar no twitter" color="info">
        <Twitter />
      </IconButton>
    </Box>
  );
};

// Componente principal
const Calendar = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [viewMode, setViewMode] = useState('calendar'); // 'calendar', 'list', 'grid'
  const [tabValue, setTabValue] = useState(0);
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setLoading(true);
        const response = await api.get('/api/events');
        
        // Formatar eventos para o calendário
        const formattedEvents = response.data.map(event => ({
          id: event._id,
          title: event.title,
          start: new Date(event.startDate),
          end: event.endDate ? new Date(event.endDate) : new Date(new Date(event.startDate).getTime() + 60 * 60 * 1000),
          description: event.description,
          location: event.location,
          category: event.category,
          allData: event
        }));
        
        setEvents(formattedEvents);
      } catch (error) {
        console.error('Erro ao carregar eventos:', error);
        setError('Não foi possível carregar os eventos. Por favor, tente novamente mais tarde.');
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  const handleEventSelect = (event) => {
    setSelectedEvent(event);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleViewModeChange = (event, newValue) => {
    setTabValue(newValue);
    switch (newValue) {
      case 0:
        setViewMode('calendar');
        break;
      case 1:
        setViewMode('list');
        break;
      case 2:
        setViewMode('grid');
        break;
      default:
        setViewMode('calendar');
    }
  };

  // Personalização do estilo dos eventos no calendário
  const eventStyleGetter = (event) => {
    let backgroundColor = '#3174ad';
    
    switch (event.category) {
      case 'worship':
        backgroundColor = '#8e24aa'; // roxo
        break;
      case 'prayer':
        backgroundColor = '#2196f3'; // azul
        break;
      case 'study':
        backgroundColor = '#4caf50'; // verde
        break;
      case 'community':
        backgroundColor = '#ff9800'; // laranja
        break;
      default:
        backgroundColor = '#3174ad'; // azul padrão
    }
    
    const style = {
      backgroundColor,
      borderRadius: '5px',
      opacity: 0.8,
      color: 'white',
      border: '0px',
      display: 'block'
    };
    
    return {
      style
    };
  };

  // Tradução das mensagens do calendário
  const messages = {
    today: 'Hoje',
    previous: 'Anterior',
    next: 'Próximo',
    month: 'Mês',
    week: 'Semana',
    day: 'Dia',
    agenda: 'Agenda',
    date: 'Data',
    time: 'Hora',
    event: 'Evento',
    noEventsInRange: 'Não há eventos neste período.',
  };

  // Filtrar eventos futuros e passados
  const futureEvents = events
    .filter(event => new Date(event.start) >= new Date())
    .sort((a, b) => new Date(a.start) - new Date(b.start));
    
  const pastEvents = events
    .filter(event => new Date(event.start) < new Date())
    .sort((a, b) => new Date(b.start) - new Date(a.start));

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh' }}>
        <CircularProgress size={60} />
      </Box>
    );
  }

  // Renderizar o conteúdo com base no modo de visualização selecionado
  const renderContent = () => {
    switch (viewMode) {
      case 'calendar':
        return (
          <Paper 
            elevation={0} 
            sx={{ 
              p: { xs: 1, sm: 2 }, 
              height: 'calc(100vh - 250px)', 
              minHeight: '500px',
              borderRadius: 2,
              boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
              overflow: 'hidden'
            }}
          >
            <BigCalendar
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              style={{ height: '100%' }}
              onSelectEvent={handleEventSelect}
              eventPropGetter={eventStyleGetter}
              messages={messages}
              culture="pt-BR"
              views={isMobile ? ['month', 'agenda'] : ['month', 'week', 'day', 'agenda']}
              defaultView={isMobile ? 'month' : 'month'}
            />
          </Paper>
        );
        
      case 'list':
        return (
          <Paper 
            elevation={0} 
            sx={{ 
              p: 2, 
              borderRadius: 2,
              boxShadow: '0 2px 12px rgba(0,0,0,0.08)'
            }}
          >
            <Typography variant="h6" gutterBottom>
              Próximos Eventos
            </Typography>
            
            {futureEvents.length > 0 ? (
              <List>
                {futureEvents.map((event) => (
                  <ListItem 
                    key={event.id} 
                    onClick={() => handleEventSelect(event)}
                    sx={{ 
                      cursor: 'pointer', 
                      borderRadius: 2, 
                      mb: 1,
                      '&:hover': {
                        bgcolor: 'rgba(0,0,0,0.04)'
                      }
                    }}
                  >
                    <ListItemIcon>
                      <Avatar sx={{ bgcolor: event.category === 'worship' ? '#8e24aa' : 
                                           event.category === 'prayer' ? '#2196f3' :
                                           event.category === 'study' ? '#4caf50' :
                                           event.category === 'community' ? '#ff9800' : '#3174ad' }}>
                        <Event />
                      </Avatar>
                    </ListItemIcon>
                    <ListItemText 
                      primary={event.title}
                      secondary={
                        <>
                          <Box component="span" sx={{ display: 'block' }}>
                            {format(new Date(event.start), 'PPP', { locale: ptBR })} às {format(new Date(event.start), 'HH:mm')}
                          </Box>
                          <Box component="span" sx={{ display: 'block' }}>
                            {event.location}
                          </Box>
                        </>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography variant="body1" color="text.secondary" align="center" sx={{ py: 4 }}>
                Não há eventos futuros programados.
              </Typography>
            )}
            
            {pastEvents.length > 0 && (
              <>
                <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
                  Eventos Anteriores
                </Typography>
                <List>
                  {pastEvents.slice(0, 5).map((event) => (
                    <ListItem 
                      key={event.id} 
                      onClick={() => handleEventSelect(event)}
                      sx={{ 
                        cursor: 'pointer', 
                        borderRadius: 2, 
                        mb: 1,
                        '&:hover': {
                          bgcolor: 'rgba(0,0,0,0.04)'
                        }
                      }}
                    >
                      <ListItemIcon>
                        <Avatar sx={{ bgcolor: 'action.disabled' }}>
                          <Event />
                        </Avatar>
                      </ListItemIcon>
                      <ListItemText 
                        primary={event.title}
                        secondary={
                          <>
                            <Box component="span" sx={{ display: 'block' }}>
                              {format(new Date(event.start), 'PPP', { locale: ptBR })} às {format(new Date(event.start), 'HH:mm')}
                            </Box>
                            <Box component="span" sx={{ display: 'block' }}>
                              {event.location}
                            </Box>
                          </>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </>
            )}
          </Paper>
        );
        
      case 'grid':
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Próximos Eventos
            </Typography>
            
            {futureEvents.length > 0 ? (
              <Grid container spacing={3} sx={{ mb: 4 }}>
                {futureEvents.map((event) => (
                  <Grid item xs={12} sm={6} md={4} key={event.id}>
                    <EventCard event={event} onClick={handleEventSelect} />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Paper 
                elevation={0} 
                sx={{ 
                  p: 4, 
                  textAlign: 'center',
                  borderRadius: 2,
                  boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
                  mb: 4
                }}
              >
                <Typography variant="body1" color="text.secondary">
                  Não há eventos futuros programados.
                </Typography>
              </Paper>
            )}
            
            {pastEvents.length > 0 && (
              <>
                <Typography variant="h6" gutterBottom>
                  Eventos Anteriores
                </Typography>
                <Grid container spacing={3}>
                  {pastEvents.slice(0, 6).map((event) => (
                    <Grid item xs={12} sm={6} md={4} key={event.id}>
                      <EventCard event={event} onClick={handleEventSelect} />
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
          </>
        );
        
      default:
        return null;
    }
  };

  return (
    <Box sx={{ mb: 4 }}>
      <Box sx={{ 
        mb: 4, 
        textAlign: 'center',
        background: 'linear-gradient(45deg, #3f51b5 30%, #2196f3 90%)',
        color: 'white',
        py: 5,
        px: 2,
        borderRadius: 2,
        boxShadow: '0 4px 20px rgba(0,0,0,0.15)'
      }}>
        <Typography variant="h3" component="h1" gutterBottom fontWeight="bold">
          Calendário de Eventos
        </Typography>
        <Typography variant="h6" sx={{ maxWidth: 700, mx: 'auto', opacity: 0.9 }}>
          Confira nossa programação e participe dos próximos eventos e encontros da igreja
        </Typography>
      </Box>
      
      {error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <>
          <Paper 
            elevation={0} 
            sx={{ 
              mb: 3, 
              borderRadius: 2,
              boxShadow: '0 2px 12px rgba(0,0,0,0.08)'
            }}
          >
            <Tabs
              value={tabValue}
              onChange={handleViewModeChange}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
              aria-label="modo de visualização"
            >
              <Tab 
                icon={<CalendarToday />} 
                label="Calendário" 
                sx={{ py: 2 }}
              />
              <Tab 
                icon={<ViewAgenda />} 
                label="Lista" 
                sx={{ py: 2 }}
              />
              <Tab 
                icon={<ViewModule />} 
                label="Grade" 
                sx={{ py: 2 }}
              />
            </Tabs>
          </Paper>
          
          {renderContent()}
        </>
      )}
      
      {/* Diálogo para mostrar detalhes do evento */}
      <Dialog 
        open={dialogOpen} 
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            overflow: 'hidden'
          }
        }}
      >
        {selectedEvent && (
          <>
            <Box sx={{ position: 'relative' }}>
              <CardMedia
                component="img"
                height="200"
                image={selectedEvent.allData.imageUrl || 
                       (selectedEvent.category === 'worship' ? 'https://images.unsplash.com/photo-1519677584237-752f8853252e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=640' :
                       selectedEvent.category === 'prayer' ? 'https://images.unsplash.com/photo-1476231682828-37e571bc172f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=640' :
                       selectedEvent.category === 'study' ? 'https://images.unsplash.com/photo-1508857662573-e78193d55857?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=640' :
                       selectedEvent.category === 'community' ? 'https://images.unsplash.com/photo-1511632765486-a01980e01a18?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=640' :
                       'https://images.unsplash.com/photo-1579684385127-1ef15d508118?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=640')}
                alt={selectedEvent.title}
              />
              
              <IconButton
                aria-label="close"
                onClick={handleCloseDialog}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: 'white',
                  bgcolor: 'rgba(0,0,0,0.5)',
                  '&:hover': {
                    bgcolor: 'rgba(0,0,0,0.7)',
                  }
                }}
              >
                <Close />
              </IconButton>
              
              <Box sx={{ 
                position: 'absolute', 
                bottom: 0, 
                width: '100%', 
                bgcolor: 'rgba(0, 0, 0, 0.6)',
                px: 2,
                py: 1
              }}>
                <Typography variant="h5" component="h2" color="white">
                  {selectedEvent.title}
                </Typography>
              </Box>
            </Box>
            
            <DialogContent>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CalendarToday sx={{ mr: 1, color: 'primary.main' }} />
                    <Typography variant="body1">
                      {format(new Date(selectedEvent.start), 'PPP', { locale: ptBR })}
                    </Typography>
                  </Box>
                </Grid>
                
                <Grid item xs={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <AccessTime sx={{ mr: 1, color: 'primary.main' }} />
                    <Typography variant="body1">
                      {format(new Date(selectedEvent.start), 'HH:mm')}
                    </Typography>
                  </Box>
                </Grid>
                
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <LocationOn sx={{ mr: 1, color: 'primary.main' }} />
                    <Typography variant="body1">
                      {selectedEvent.location}
                    </Typography>
                  </Box>
                </Grid>
                
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Category sx={{ mr: 1, color: 'primary.main' }} />
                    <Typography variant="body1">
                      {selectedEvent.category === 'worship' ? 'Culto' :
                       selectedEvent.category === 'prayer' ? 'Oração' :
                       selectedEvent.category === 'study' ? 'Estudo' :
                       selectedEvent.category === 'community' ? 'Comunidade' : 'Outro'}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              
              <Divider sx={{ my: 2 }} />
              
              <Typography variant="body1" paragraph>
                {selectedEvent.description}
              </Typography>
              
              <Typography variant="subtitle1" align="center" sx={{ mt: 3, mb: 1 }}>
                Compartilhe este evento
              </Typography>
              
              <ShareButtons event={selectedEvent} />
            </DialogContent>
            
            <DialogActions sx={{ p: 2, pt: 0 }}>
              <Button 
                onClick={handleCloseDialog} 
                variant="outlined"
              >
                Fechar
              </Button>
              
              <Button 
                variant="contained"
                color="primary"
                startIcon={<Event />}
                onClick={() => {
                  // Adicionar ao calendário do Google
                  const startTime = encodeURIComponent(new Date(selectedEvent.start).toISOString());
                  const endTime = encodeURIComponent(new Date(selectedEvent.end).toISOString());
                  const title = encodeURIComponent(selectedEvent.title);
                  const location = encodeURIComponent(selectedEvent.location);
                  const details = encodeURIComponent(selectedEvent.description);
                  
                  const url = `https://www.google.com/calendar/render?action=TEMPLATE&text=${title}&dates=${startTime}/${endTime}&details=${details}&location=${location}`;
                  window.open(url, '_blank');
                }}
              >
                Adicionar ao Calendário
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Box>
  );
};

export default Calendar;