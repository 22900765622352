import React from 'react';
import { Outlet } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Container, Box } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';

const PublicLayout = () => {
  const { currentUser } = useAuth();

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component={RouterLink} to="/" sx={{ flexGrow: 1, textDecoration: 'none', color: 'white' }}>
            Povo Fiel
          </Typography>
          {/*<Button color="inherit" component={RouterLink} to="/calendario">
            Calendário
          </Button>*/}
          {currentUser ? (
            <>
              <Button 
                color="inherit" 
                component={RouterLink} 
                to={currentUser.role === 'admin' ? '/admin' : '/area-membro'}
              >
                Área do {currentUser.role === 'admin' ? 'Admin' : 'Membro'}
              </Button>
            </>
          ) : (
            <>
              {/*<Button color="inherit" component={RouterLink} to="/login">
                Login
              </Button>*/}
              {/*<Button color="inherit" component={RouterLink} to="/register">
                Cadastrar
              </Button>*/}
            </>
          )}
        </Toolbar>
      </AppBar>
      
      <Container component="main" sx={{ mt: 4, mb: 4 }}>
        <Box sx={{ minHeight: 'calc(100vh - 140px)' }}>
          <Outlet />
        </Box>
      </Container>
      
      <Box component="footer" sx={{ bgcolor: '#f5f5f5', py: 3, textAlign: 'center' }}>
        <Typography variant="body2" color="text.secondary">
          &copy; {new Date().getFullYear()} Igreja Online - Todos os direitos reservados
        </Typography>
      </Box>
    </>
  );
};

export default PublicLayout;
