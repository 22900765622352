import React, { useState, useEffect } from 'react';
import { useNavigate, Link as RouterLink, useParams, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import api from '../../services/api';
import axios from 'axios';
import {
  Avatar, Button, TextField, Link, Grid, Box, Typography, Container, Alert,
  Stepper, Step, StepLabel
} from '@mui/material';
import { LockOutlined as LockOutlinedIcon, Church as ChurchIcon } from '@mui/icons-material';

// Função para extrair parâmetros da URL
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Register = () => {
  const { churchSlug } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const query = useQuery();
  
  // Dados do formulário de lead obtidos da URL
  const nameFromURL = query.get('name');
  const churchNameFromURL = query.get('churchName');
  const phoneFromURL = query.get('phone');
  const emailFromURL = query.get('email');
  
  // Inicializar com dados do lead se existirem
  const storedLeadData = JSON.parse(localStorage.getItem('leadData') || '{}');
  
  const [formData, setFormData] = useState({
    // User data
    name: nameFromURL || storedLeadData.name || '',
    email: emailFromURL || storedLeadData.email || '',
    phone: phoneFromURL || storedLeadData.phone || '',
    password: '',
    confirmPassword: '',
    // Church data
    churchName: churchNameFromURL || storedLeadData.churchName || '',
    churchPhone: phoneFromURL || storedLeadData.phone || '',
    churchEmail: emailFromURL || storedLeadData.email || '',
    churchCity: ''
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { register } = useAuth();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleNext = () => {
    // Validação dos campos na primeira etapa
    if (activeStep === 0) {
      if (!formData.name || !formData.email || !formData.password) {
        return setError('Por favor, preencha todos os campos obrigatórios.');
      }
      
      if (formData.password !== formData.confirmPassword) {
        return setError('As senhas não coincidem.');
      }
      
      // Validação básica de senha - apenas comprimento mínimo
      if (formData.password.length < 4) {
        return setError('A senha deve ter pelo menos 4 caracteres.');
      }
    }
    
    // Validação dos campos na segunda etapa
    if (activeStep === 1) {
      if (!formData.churchName) {
        return setError('Por favor, informe o nome da igreja.');
      }
    }
    
    setError('');
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (activeStep === 0) {
      return handleNext();
    }
    
    try {
      setError('');
      setLoading(true);
      
      // Primeiro registrar o usuário
      const user = await register(
        formData.name,
        formData.email,
        formData.password,
        formData.phone
      );
      
      console.log('Usuário registrado:', user);
      
      // Depois criar a igreja associada a este usuário
      const churchData = {
        name: formData.churchName,
        city: formData.churchCity,
        phone: formData.churchPhone,
        email: formData.churchEmail || formData.email, // Usar o email do usuário como fallback
        createdBy: user.id
      };
      
      console.log('Criando igreja com dados:', churchData);
      
      // Chamar API para criar igreja
      const churchResponse = await api.post('/api/churches', churchData);
      
      console.log('Resposta da criação da igreja:', churchResponse.data);
      
      // Limpar dados do lead do localStorage
      localStorage.removeItem('leadData');
      
      // Obter o ID e slug da igreja criada
      const churchId = churchResponse.data.church.id;
      const churchSlug = churchResponse.data.church.slug;
      console.log('Igreja criada com ID:', churchId, 'e slug:', churchSlug);
      
      // Carregar o usuário atualizado que agora deve estar associado à igreja e ter o papel de admin
      try {
        console.log('Obtendo dados atualizados do usuário após criação da igreja...');
        const userResponse = await api.get('/api/auth/me');
        
        if (userResponse.data && userResponse.data.user) {
          console.log('Dados atualizados do usuário:', userResponse.data.user);
          
          // Atualizar o usuário atual no localStorage
          localStorage.setItem('currentUser', JSON.stringify(userResponse.data.user));
          
          // Redirecionar para o dashboard administrativo
          console.log('Redirecionando para o painel administrativo');
          setTimeout(() => {
            navigate('/admin');
          }, 500);
        } else {
          throw new Error('Dados de usuário inválidos');
        }
      } catch (error) {
        console.error('Erro ao obter dados atualizados do usuário:', error);
        
        // Criar uma nova instância de api com o token de autenticação
        const authApi = axios.create({
          baseURL: api.defaults.baseURL,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        
        // Mesmo com erro, tentar realizar uma última tentativa de configuração de admin
        try {
          console.log('Tentativa final de configuração de admin...');
          await authApi.post(`/api/churches/${churchSlug}/setup-admin`, {
            userId: user.id
          });
          navigate('/admin');
        } catch (finalError) {
          console.error('Erro na tentativa final:', finalError);
          navigate('/admin');
        }
      }
    } catch (err) {
      setError(err.message || 'Ocorreu um erro ao processar seu cadastro.');
    } finally {
      setLoading(false);
    }
  };

  const steps = ['Dados Pessoais', 'Dados da Igreja'];

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          {activeStep === 0 ? <LockOutlinedIcon /> : <ChurchIcon />}
        </Avatar>
        <Typography component="h1" variant="h5">
          {activeStep === 0 ? 'Cadastro' : 'Dados da Igreja'}
        </Typography>
        
        <Stepper activeStep={activeStep} sx={{ width: '100%', mt: 3 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        
        {error && (
          <Alert severity="error" sx={{ mt: 2, width: '100%' }}>
            {error}
          </Alert>
        )}
        
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3, width: '100%' }}>
          {activeStep === 0 ? (
            // Etapa 1: Dados Pessoais
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="name"
                  name="name"
                  required
                  fullWidth
                  id="name"
                  label="Nome Completo"
                  autoFocus
                  value={formData.name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="phone"
                  label="Telefone (com DDD)"
                  name="phone"
                  autoComplete="tel"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Senha"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  value={formData.password}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirmar Senha"
                  type="password"
                  id="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          ) : (
            // Etapa 2: Dados da Igreja
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="organization"
                  name="churchName"
                  required
                  fullWidth
                  id="churchName"
                  label="Nome da Igreja"
                  autoFocus
                  value={formData.churchName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="churchCity"
                  label="Cidade da Igreja"
                  name="churchCity"
                  autoComplete="address-level2"
                  value={formData.churchCity}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="churchPhone"
                  label="Telefone da Igreja"
                  name="churchPhone"
                  autoComplete="tel"
                  value={formData.churchPhone}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="churchEmail"
                  label="Email da Igreja"
                  name="churchEmail"
                  autoComplete="email"
                  value={formData.churchEmail}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          )}
          
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3, mb: 2 }}>
            {activeStep > 0 && (
              <Button
                onClick={handleBack}
                variant="outlined"
                sx={{ mr: 1 }}
              >
                Voltar
              </Button>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={loading}
            >
              {activeStep === steps.length - 1 
                ? (loading ? 'Cadastrando...' : 'Finalizar Cadastro') 
                : 'Próximo'
              }
            </Button>
          </Box>
          
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link component={RouterLink} to="/login" variant="body2">
                Já tem uma conta? Faça login
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default Register;
