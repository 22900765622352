import React, { useState } from 'react';
import { Outlet, useNavigate, Link as RouterLink, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import {
  AppBar, Toolbar, Typography, Button, Container, Box,
  Drawer, List, ListItem, ListItemIcon, ListItemText, Divider,
  IconButton, useMediaQuery, useTheme, Avatar, Badge, Tooltip,
  ListItemButton, SwipeableDrawer, Paper, Fab, Zoom, Collapse
} from '@mui/material';
import {
  Menu as MenuIcon, Dashboard, People, AttachMoney,
  Message, Event, WhatsApp, Logout, ChevronLeft,
  NotificationsActive, Settings, AccountCircle, KeyboardArrowUp,
  Home, Close, ChevronRight, Notifications
} from '@mui/icons-material';

const AdminLayout = () => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [drawerOpen, setDrawerOpen] = useState(!isMobile);
  const [showScrollTop, setShowScrollTop] = useState(false);

  // Monitor scroll position to show/hide scroll-to-top button
  React.useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.pageYOffset > 400);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const drawerWidth = isMobile ? '85%' : 280; // Wider on desktop, narrower on mobile

  // Menu items with more descriptive info for better UX
  const menuItems = [
    { 
      text: 'Dashboard', 
      icon: <Dashboard />, 
      path: '/admin',
      description: 'Visão geral da igreja'
    },
    { 
      text: 'Membros', 
      icon: <People />, 
      path: '/admin/membros',
      description: 'Gerenciar cadastros'
    },
    { 
      text: 'Dízimos', 
      icon: <AttachMoney />, 
      path: '/admin/dizimos',
      description: 'Contribuições e finanças'
    },
    { 
      text: 'Eventos', 
      icon: <Event />, 
      path: '/admin/eventos',
      description: 'Agenda e encontros'
    },
    { 
      text: 'Mensagens', 
      icon: <Message />, 
      path: '/admin/mensagens',
      description: 'Comunicados e estudos'
    },
    { 
      text: 'WhatsApp', 
      icon: <WhatsApp />, 
      path: '/admin/whatsapp',
      description: 'Integração de mensagens'
    },
    { 
      text: 'Rotinas de Comunicações', 
      icon: <Notifications />, 
      path: '/admin/comunicacoes',
      description: 'Automação de mensagens'
    },
  ];

  // Function to check if current path matches menu item
  const isActiveRoute = (path) => {
    if (path === '/admin') {
      return location.pathname === '/admin';
    }
    return location.pathname.startsWith(path);
  };

  const drawer = (
    <>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',
        position: 'relative',
        py: 3,
        background: 'linear-gradient(135deg, #3f51b5 0%, #303f9f 100%)',
        color: 'white'
      }}>
        {isMobile && (
          <IconButton 
            color="inherit" 
            onClick={toggleDrawer} 
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <Close />
          </IconButton>
        )}
        
        <Avatar 
          sx={{ 
            width: 80, 
            height: 80, 
            mb: 2,
            bgcolor: 'primary.light',
            border: '3px solid white',
            boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
          }}
        >
          {currentUser?.name?.charAt(0) || <AccountCircle />}
        </Avatar>
        
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          {currentUser?.name || 'Administrador'}
        </Typography>
        
        <Typography variant="body2" sx={{ opacity: 0.9, mb: 2 }}>
          Administrador
        </Typography>
        
        <Button 
          variant="outlined" 
          size="small" 
          sx={{ 
            borderColor: 'rgba(255,255,255,0.5)', 
            color: 'white',
            '&:hover': {
              borderColor: 'white',
              backgroundColor: 'rgba(255,255,255,0.1)'
            }
          }}
          component={RouterLink}
          to="/"
          startIcon={<Home />}
        >
          Ir para o Site
        </Button>
      </Box>
      
      <Box sx={{ overflow: 'auto', flexGrow: 1 }}>
        <List component="nav" sx={{ px: 1, py: 2 }}>
          {menuItems.map((item) => (
            <ListItem 
              key={item.text}
              disablePadding
              sx={{ mb: 0.5 }}
            >
              <ListItemButton
                component={RouterLink}
                to={item.path}
                onClick={isMobile ? toggleDrawer : undefined}
                sx={{
                  borderRadius: 2,
                  py: 1.2,
                  bgcolor: isActiveRoute(item.path) ? 'rgba(63, 81, 181, 0.12)' : 'transparent',
                  '&:hover': {
                    bgcolor: isActiveRoute(item.path) ? 'rgba(63, 81, 181, 0.18)' : 'rgba(0, 0, 0, 0.04)'
                  }
                }}
              >
                <ListItemIcon
                  sx={{
                    color: isActiveRoute(item.path) ? 'primary.main' : 'inherit',
                    minWidth: 48
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText 
                  primary={item.text}
                  secondary={item.description}
                  primaryTypographyProps={{
                    fontWeight: isActiveRoute(item.path) ? 'bold' : 'normal',
                    color: isActiveRoute(item.path) ? 'primary.main' : 'inherit',
                  }}
                  secondaryTypographyProps={{
                    fontSize: '0.75rem',
                    display: { xs: 'none', sm: 'block' }
                  }}
                />
                {isActiveRoute(item.path) && (
                  <Box 
                    sx={{ 
                      width: 4, 
                      height: 36, 
                      bgcolor: 'primary.main',
                      borderRadius: 4,
                      ml: 1
                    }} 
                  />
                )}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        
        <Divider sx={{ mx: 2 }} />
        
        <List>
          <ListItem disablePadding sx={{ px: 1, py: 1 }}>
            <ListItemButton 
              onClick={handleLogout}
              sx={{
                borderRadius: 2,
                py: 1.2,
                color: theme.palette.error.main,
                '&:hover': {
                  bgcolor: `${theme.palette.error.light}20`
                }
              }}
            >
              <ListItemIcon sx={{ color: 'inherit', minWidth: 48 }}>
                <Logout />
              </ListItemIcon>
              <ListItemText primary="Sair" />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </>
  );

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <AppBar 
        position="fixed" 
        elevation={1}
        sx={{ 
          zIndex: (theme) => theme.zIndex.drawer + 1,
          bgcolor: 'white',
          color: 'text.primary'
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            edge="start"
            onClick={toggleDrawer}
            sx={{ mr: 2, display: isMobile ? 'block' : 'none' }}
          >
            <MenuIcon />
          </IconButton>
          
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography 
              variant="h6" 
              component={RouterLink} 
              to="/admin" 
              sx={{ 
                textDecoration: 'none', 
                color: 'inherit',
                display: 'flex',
                alignItems: 'center',
                fontWeight: 'bold',
                '& .logo-text': {
                  display: { xs: 'none', sm: 'block' }
                }
              }}
            >
              <Avatar sx={{ bgcolor: 'primary.main', width: 30, height: 30, mr: 1 }}>
                <Settings sx={{ fontSize: 18 }} />
              </Avatar>
              <span className="logo-text">Painel Admin</span>
            </Typography>
          </Box>
          
          <Box sx={{ flexGrow: 1 }} />
          
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Tooltip title="Notificações">
              <IconButton color="inherit">
                <Badge badgeContent={3} color="error">
                  <NotificationsActive />
                </Badge>
              </IconButton>
            </Tooltip>
            
            <Tooltip title="Configurações">
              <IconButton color="inherit">
                <Settings />
              </IconButton>
            </Tooltip>
            
            {isMobile && (
              <Tooltip title="Perfil">
                <IconButton
                  onClick={() => {}}
                  size="small"
                >
                  <Avatar 
                    sx={{ width: 32, height: 32, bgcolor: 'primary.main' }}
                  >
                    {currentUser?.name?.charAt(0) || <AccountCircle />}
                  </Avatar>
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      
      {/* Drawer - Swipeable on mobile for better UX */}
      {isMobile ? (
        <SwipeableDrawer
          anchor="left"
          open={drawerOpen}
          onClose={toggleDrawer}
          onOpen={toggleDrawer}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': { 
              width: drawerWidth, 
              boxSizing: 'border-box',
              borderRight: '1px solid rgba(0, 0, 0, 0.08)',
            },
          }}
        >
          {drawer}
        </SwipeableDrawer>
      ) : (
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': { 
              width: drawerWidth, 
              boxSizing: 'border-box',
              borderRight: '1px solid rgba(0, 0, 0, 0.08)',
              boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 2px 0px'
            },
          }}
        >
          <Toolbar />
          {drawer}
        </Drawer>
      )}
      
      {/* Main content */}
      <Box 
        component="main" 
        sx={{ 
          flexGrow: 1, 
          p: { xs: 2, sm: 3 },
          bgcolor: '#f8f9fa',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Toolbar />
        
        <Paper 
          elevation={0} 
          sx={{ 
            p: { xs: 2, sm: 3 }, 
            borderRadius: 2, 
            flexGrow: 1,
            mb: 3,
            boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 2px 0px'
          }}
        >
          <Outlet />
        </Paper>
        
        <Box 
          component="footer" 
          sx={{ 
            p: 2, 
            textAlign: 'center', 
            borderRadius: 2,
            bgcolor: 'white'
          }}
        >
          <Typography variant="body2" color="text.secondary">
            &copy; {new Date().getFullYear()} Igreja Online - Todos os direitos reservados
          </Typography>
        </Box>
      </Box>
      
      {/* Scroll to top button */}
      <Zoom in={showScrollTop}>
        <Box
          role="presentation"
          sx={{
            position: 'fixed',
            bottom: 20,
            right: 20,
            zIndex: 1
          }}
        >
          <Fab
            color="primary"
            size="small"
            aria-label="voltar ao topo"
            onClick={handleScrollTop}
          >
            <KeyboardArrowUp />
          </Fab>
        </Box>
      </Zoom>
    </Box>
  );
};

export default AdminLayout;