import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import {
  Typography, Box, Paper, TextField, Button, Grid,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Dialog, DialogTitle, DialogContent, DialogActions, IconButton,
  Alert, CircularProgress, Divider, Chip, InputAdornment,
  Card, CardContent, FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import {
  Search, AttachMoney, CreditCard, CalendarMonth, Person, Close
} from '@mui/icons-material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import api from '../../services/api';

const TitheManagement = () => {
  const { currentUser } = useAuth();
  const [tithes, setTithes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedTithe, setSelectedTithe] = useState(null);
  const [timeframe, setTimeframe] = useState('month');
  const [stats, setStats] = useState({
    total: 0,
    monthly: 0,
    oneTime: 0,
    count: 0
  });
  const [chartData, setChartData] = useState([]);
  const [churchSlug, setChurchSlug] = useState('');

  // Obter o slug da igreja quando o componente montar
  useEffect(() => {
    const fetchChurchSlug = async () => {
      if (currentUser && currentUser.church) {
        try {
          const response = await api.get(`/api/churches`);
          const churches = response.data.churches;
          
          // Encontrar a igreja do usuário
          const church = churches.find(c => c._id === currentUser.church);
          
          if (church && church.slug) {
            setChurchSlug(church.slug);
          }
        } catch (error) {
          console.error("Erro ao buscar slug da igreja:", error);
        }
      }
    };
    
    fetchChurchSlug();
    fetchTithes();
  }, [currentUser]);

  const fetchTithes = async () => {
    try {
      setLoading(true);
      const response = await api.get('/api/tithes');
      
      // Ordenar por data (mais recentes primeiro)
      const sortedTithes = response.data.sort((a, b) => 
        new Date(b.createdAt) - new Date(a.createdAt)
      );
      
      setTithes(sortedTithes);
      calculateStats(sortedTithes);
      generateChartData(sortedTithes, timeframe);
    } catch (err) {
      console.error('Erro ao carregar dízimos:', err);
      setError('Não foi possível carregar a lista de dízimos.');
    } finally {
      setLoading(false);
    }
  };

  const calculateStats = (titheData) => {
    // Calcular estatísticas gerais
    const total = titheData.reduce((sum, tithe) => sum + tithe.amount, 0);
    const monthlyTithes = titheData.filter(tithe => tithe.frequency === 'monthly');
    const oneTimeTithes = titheData.filter(tithe => tithe.frequency === 'one-time');
    
    setStats({
      total,
      monthly: monthlyTithes.reduce((sum, tithe) => sum + tithe.amount, 0),
      oneTime: oneTimeTithes.reduce((sum, tithe) => sum + tithe.amount, 0),
      count: titheData.length
    });
  };

  const generateChartData = (titheData, period) => {
    const now = new Date();
    let data = [];
    
    if (period === 'week') {
      // Dados dos últimos 7 dias
      const days = [];
      for (let i = 6; i >= 0; i--) {
        const date = new Date(now);
        date.setDate(date.getDate() - i);
        days.push({
          date,
          label: date.toLocaleDateString('pt-BR', { weekday: 'short' }),
          value: 0
        });
      }
      
      // Somar valores para cada dia
      titheData.forEach(tithe => {
        const titheDate = new Date(tithe.createdAt);
        days.forEach(day => {
          if (titheDate.toDateString() === day.date.toDateString()) {
            day.value += tithe.amount;
          }
        });
      });
      
      data = days.map(day => ({ name: day.label, value: day.value }));
    } else if (period === 'month') {
      // Dados dos últimos 30 dias agrupados por semana
      const weeks = [];
      for (let i = 3; i >= 0; i--) {
        const endDate = new Date(now);
        endDate.setDate(endDate.getDate() - (i * 7));
        const startDate = new Date(endDate);
        startDate.setDate(startDate.getDate() - 6);
        
        weeks.push({
          startDate,
          endDate,
          label: `Semana ${4-i}`,
          value: 0
        });
      }
      
      // Somar valores para cada semana
      titheData.forEach(tithe => {
        const titheDate = new Date(tithe.createdAt);
        weeks.forEach(week => {
          if (titheDate >= week.startDate && titheDate <= week.endDate) {
            week.value += tithe.amount;
          }
        });
      });
      
      data = weeks.map(week => ({ name: week.label, value: week.value }));
    } else if (period === 'year') {
      // Dados dos últimos 12 meses
      const months = [];
      for (let i = 11; i >= 0; i--) {
        const date = new Date(now.getFullYear(), now.getMonth() - i, 1);
        months.push({
          date,
          label: date.toLocaleDateString('pt-BR', { month: 'short' }),
          value: 0
        });
      }
      
      // Somar valores para cada mês
      titheData.forEach(tithe => {
        const titheDate = new Date(tithe.createdAt);
        months.forEach(month => {
          if (titheDate.getMonth() === month.date.getMonth() && 
              titheDate.getFullYear() === month.date.getFullYear()) {
            month.value += tithe.amount;
          }
        });
      });
      
      data = months.map(month => ({ name: month.label, value: month.value }));
    }
    
    setChartData(data);
  };

  const handleViewDetails = (tithe) => {
    setSelectedTithe(tithe);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleTimeframeChange = (event) => {
    const newTimeframe = event.target.value;
    setTimeframe(newTimeframe);
    generateChartData(tithes, newTimeframe);
  };

  // Filtrar dízimos de acordo com a busca
  const filteredTithes = tithes.filter(tithe => {
    const searchLower = searchTerm.toLowerCase();
    return (
      (tithe.member?.name && tithe.member.name.toLowerCase().includes(searchLower)) ||
      (tithe.member?.email && tithe.member.email.toLowerCase().includes(searchLower))
    );
  });

  if (loading && tithes.length === 0) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  const formatCurrency = (value) => {
    return `R$ ${value.toFixed(2)}`;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Box sx={{ bgcolor: 'background.paper', p: 2, border: '1px solid #ccc', borderRadius: 1 }}>
          <Typography variant="subtitle2">{label}</Typography>
          <Typography variant="body2" color="primary">
            {`Valor: ${formatCurrency(payload[0].value)}`}
          </Typography>
        </Box>
      );
    }
    return null;
  };

  return (
    <Box>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        flexWrap: 'wrap',
        mb: 2
      }}>
        <Box>
          <Typography variant="h4" gutterBottom>
            Gestão de Dízimos
          </Typography>
          <Typography variant="body1">
            Acompanhe as contribuições e dízimos dos membros da igreja.
          </Typography>
        </Box>
        
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button 
            variant="outlined" 
            color="primary"
            component={RouterLink}
            to="/area-membro/dizimo"
            startIcon={<AttachMoney />}
          >
            Meus Dízimos
          </Button>
          
          <Button 
            variant="contained" 
            color="primary"
            component={RouterLink}
            to={churchSlug ? `/doar/${churchSlug}` : '#'}
            disabled={!churchSlug}
            sx={{ 
              background: 'linear-gradient(45deg, #2E7D32 30%, #4CAF50 90%)',
              fontWeight: 'bold',
              boxShadow: '0 4px 12px rgba(76, 175, 80, 0.3)',
              '&:hover': {
                background: 'linear-gradient(45deg, #1B5E20 30%, #388E3C 90%)',
                boxShadow: '0 6px 14px rgba(76, 175, 80, 0.4)',
              }
            }}
            startIcon={<AttachMoney />}
          >
            Página de Doação
          </Button>
        </Box>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      {/* Cards de estatísticas */}
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                Total de Contribuições
              </Typography>
              <Typography variant="h4" component="div">
                {formatCurrency(stats.total)}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                De {stats.count} contribuições
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                Contribuições Mensais
              </Typography>
              <Typography variant="h4" component="div">
                {formatCurrency(stats.monthly)}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                Assinaturas recorrentes
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                Contribuições Únicas
              </Typography>
              <Typography variant="h4" component="div">
                {formatCurrency(stats.oneTime)}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                Pagamentos únicos
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                Média por Contribuição
              </Typography>
              <Typography variant="h4" component="div">
                {stats.count > 0 ? formatCurrency(stats.total / stats.count) : 'R$ 0,00'}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                Valor médio
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Gráfico de Contribuições */}
      <Paper sx={{ p: 3, mb: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6">Histórico de Contribuições</Typography>
          <FormControl sx={{ minWidth: 150 }}>
            <InputLabel>Período</InputLabel>
            <Select
              value={timeframe}
              onChange={handleTimeframeChange}
              label="Período"
              size="small"
            >
              <MenuItem value="week">Semanal</MenuItem>
              <MenuItem value="month">Mensal</MenuItem>
              <MenuItem value="year">Anual</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Divider sx={{ mb: 2 }} />
        
        <Box sx={{ height: 300, mb: 3 }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={chartData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Bar dataKey="value" fill="#3f51b5" name="Valor" />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Paper>

      {/* Tabela de Dízimos */}
      <Paper sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
          <TextField
            label="Buscar por membro"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ width: 300 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Membro</TableCell>
                <TableCell>Data</TableCell>
                <TableCell>Valor</TableCell>
                <TableCell>Tipo</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Detalhes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredTithes.length > 0 ? (
                filteredTithes.map((tithe) => (
                  <TableRow key={tithe._id}>
                    <TableCell>
                      {tithe.member?.name || 'Membro'}
                    </TableCell>
                    <TableCell>
                      {new Date(tithe.createdAt).toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2" fontWeight="bold">
                        {formatCurrency(tithe.amount)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Chip 
                        label={tithe.frequency === 'monthly' ? 'Mensal' : 'Único'} 
                        color={tithe.frequency === 'monthly' ? 'primary' : 'default'}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <Chip 
                        label={
                          tithe.status === 'active' ? 'Ativo' :
                          tithe.status === 'canceled' ? 'Cancelado' : 'Falhou'
                        } 
                        color={
                          tithe.status === 'active' ? 'success' :
                          tithe.status === 'canceled' ? 'default' : 'error'
                        }
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        size="small"
                        onClick={() => handleViewDetails(tithe)}
                        startIcon={<Person />}
                      >
                        Detalhes
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    {searchTerm ? 'Nenhum dízimo encontrado para esta busca.' : 'Nenhum dízimo registrado ainda.'}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* Diálogo de detalhes do dízimo */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>
          Detalhes da Contribuição
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {selectedTithe && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Informações do Membro
                </Typography>
                <Typography variant="body1">
                  <strong>Nome:</strong> {selectedTithe.member?.name || 'Não disponível'}
                </Typography>
                <Typography variant="body1">
                  <strong>Email:</strong> {selectedTithe.member?.email || 'Não disponível'}
                </Typography>
              </Grid>
              
              <Grid item xs={12}>
                <Divider sx={{ my: 1 }} />
                <Typography variant="subtitle1" gutterBottom>
                  Detalhes do Pagamento
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <AttachMoney color="primary" />
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    <strong>Valor:</strong> {formatCurrency(selectedTithe.amount)}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <CalendarMonth color="primary" />
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    <strong>Data:</strong> {new Date(selectedTithe.createdAt).toLocaleDateString()}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <CreditCard color="primary" />
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    <strong>Método:</strong> {
                      selectedTithe.paymentMethod === 'credit_card' ? 'Cartão de Crédito' :
                      selectedTithe.paymentMethod === 'debit_card' ? 'Cartão de Débito' :
                      'Transferência Bancária'
                    }
                  </Typography>
                </Box>
              </Grid>
              
              <Grid item xs={12}>
                <Divider sx={{ my: 1 }} />
                <Typography variant="subtitle1" gutterBottom>
                  Status da Assinatura
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                  <Chip 
                    label={selectedTithe.frequency === 'monthly' ? 'Assinatura Mensal' : 'Pagamento Único'} 
                    color="primary"
                    sx={{ mr: 1, mb: 1 }}
                  />
                  <Chip 
                    label={
                      selectedTithe.status === 'active' ? 'Ativa' :
                      selectedTithe.status === 'canceled' ? 'Cancelada' : 'Falhou'
                    } 
                    color={
                      selectedTithe.status === 'active' ? 'success' :
                      selectedTithe.status === 'canceled' ? 'default' : 'error'
                    }
                    sx={{ mr: 1, mb: 1 }}
                  />
                </Box>
                
                {selectedTithe.frequency === 'monthly' && selectedTithe.nextPaymentDate && (
                  <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                    <strong>Próxima cobrança:</strong> {new Date(selectedTithe.nextPaymentDate).toLocaleDateString()}
                  </Typography>
                )}
                
                {selectedTithe.lastPaymentDate && (
                  <Typography variant="body2" color="text.secondary">
                    <strong>Último pagamento:</strong> {new Date(selectedTithe.lastPaymentDate).toLocaleDateString()}
                  </Typography>
                )}
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TitheManagement;
