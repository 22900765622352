import React, { useState, useEffect } from 'react';
import {
  Typography, Box, Paper, TextField, Button, Grid, Divider,
  List, ListItem, ListItemText, ListItemAvatar, Avatar, CircularProgress,
  Card, CardContent, Alert, Tab, Tabs, FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import {
  WhatsApp, QrCode, Message, Send, Refresh, Group, PersonAdd, Person
} from '@mui/icons-material';
import api from '../../services/api';

const WhatsappDashboard = () => {
  const [status, setStatus] = useState({
    connected: false,
    qrCode: null
  });
  const [loading, setLoading] = useState(true);
  const [loadingAction, setLoadingAction] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const [members, setMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [broadcastType, setBroadcastType] = useState('all');
  const [message, setMessage] = useState('');
  const [directMessageData, setDirectMessageData] = useState({
    phone: '',
    message: ''
  });

  // Buscar status inicial do WhatsApp
  useEffect(() => {
    const fetchWhatsAppStatus = async () => {
      try {
        setLoading(true);
        const response = await api.get('/api/whatsapp/status');
        console.log('Status recebido:', response.data);
        setStatus(response.data);
      } catch (error) {
        console.error('Erro ao buscar status do WhatsApp:', error);
        setError('Erro ao conectar ao serviço de WhatsApp.');
      } finally {
        setLoading(false);
      }
    };

    fetchWhatsAppStatus();
    
    // Verificar status a cada 2 segundos para atualizar o QR code se necessário
    // Isso é importante para garantir que o QR code seja capturado assim que estiver disponível
    const intervalId = setInterval(fetchWhatsAppStatus, 15000);
    
    // Limpar o intervalo quando o componente for desmontado
    return () => clearInterval(intervalId);
  }, []);

  // Buscar membros para envio de mensagens
  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const response = await api.get('/api/members');
        // Filtrar apenas membros com telefone
        setMembers(response.data.filter(member => member.phone));
      } catch (error) {
        console.error('Erro ao buscar membros:', error);
      }
    };

    fetchMembers();
  }, []);

  // Conectar ao WhatsApp
  const handleConnect = async () => {
    try {
      setLoadingAction(true);
      setError('');
      setSuccess('');
      
      // Mostrar mensagem para o usuário
      setSuccess('Inicializando conexão com WhatsApp, aguarde...');
      
      const response = await api.post('/api/whatsapp/connect');
      console.log('Resposta de conexão:', response.data);
      
      // Atualizar status
      setSuccess(response.data.message || 'Gerando QR Code, aguarde...');
      
      // Aguardar um pouco e então verificar se o QR code já está disponível
      setTimeout(async () => {
        try {
          const statusResponse = await api.get('/api/whatsapp/status');
          console.log('Status após solicitar conexão:', statusResponse.data);
          setStatus(statusResponse.data);
          
          if (statusResponse.data.qrCode) {
            setSuccess('QR Code gerado! Escaneie-o com seu celular.');
          } else if (statusResponse.data.connected) {
            setSuccess('WhatsApp conectado com sucesso!');
          } else {
            setSuccess('Gerando QR Code, aguarde mais alguns segundos...');
          }
        } catch (err) {
          console.error('Erro ao verificar status inicial:', err);
        } finally {
          setLoadingAction(false);
        }
      }, 3000);
      
    } catch (err) {
      console.error('Erro ao conectar:', err);
      setError(err.response?.data?.error || 'Erro ao conectar ao WhatsApp.');
      setLoadingAction(false);
    }
  };

  // Desconectar do WhatsApp
  const handleDisconnect = async () => {
    try {
      setLoadingAction(true);
      setError('');
      setSuccess('');
      
      const response = await api.post('/api/whatsapp/disconnect');
      
      setStatus({ connected: false, qrCode: null });
      setSuccess(response.data.message);
    } catch (err) {
      setError(err.response?.data?.error || 'Erro ao desconectar do WhatsApp.');
    } finally {
      setLoadingAction(false);
    }
  };

  // Atualizar status e solicitar uma nova geração de QR code
  const refreshStatus = async () => {
    try {
      setLoadingAction(true);
      setError('');
      setSuccess('');
      
      // Mostrar uma mensagem ao usuário
      setSuccess('Verificando status da conexão...');
      
      // Verificar status atual
      const response = await api.get('/api/whatsapp/status');
      console.log('Status atual:', response.data);
      
      if (response.data.connected) {
        setStatus(response.data);
        setSuccess('WhatsApp já está conectado!');
        setLoadingAction(false);
        return;
      }
      
      // Iniciar uma nova conexão
      setSuccess('Iniciando conexão com WhatsApp...');
      await api.post('/api/whatsapp/connect')
        .then(response => {
          console.log('Conexão solicitada com sucesso:', response.data);
          setSuccess(response.data.message || 'Verificando WhatsApp...');
        })
        .catch(err => {
          console.error('Erro ao solicitar conexão:', err);
          throw err;
        });
      
      // Verificar status periodicamente para pegar o QR code ou confirmação de conexão
      let attempts = 0;
      const maxAttempts = 15; // 30 segundos no máximo
      
      const intervalId = setInterval(async () => {
        try {
          attempts++;
          console.log(`Verificando status (tentativa ${attempts})...`);
          
          const statusResponse = await api.get('/api/whatsapp/status');
          console.log('Resultado da verificação:', statusResponse.data);
          
          // Atualizar estado com a resposta
          setStatus(statusResponse.data);
          
          if (statusResponse.data.connected) {
            clearInterval(intervalId);
            setSuccess('WhatsApp conectado com sucesso!');
            setLoadingAction(false);
          } else if (statusResponse.data.qrCode) {
            // Se tiver QR code, pararmos a verificação automática
            // pois o usuário vai precisar escanear o QR code
            clearInterval(intervalId);
            setSuccess('QR Code disponível! Veja as instruções para escanear no console do servidor.');
            setLoadingAction(false);
          }
          
          // Se atingiu o máximo de tentativas
          if (attempts >= maxAttempts) {
            clearInterval(intervalId);
            if (!statusResponse.data.connected && !statusResponse.data.qrCode) {
              setError('Tempo limite excedido. Verifique o console do servidor para mais informações.');
            }
            setLoadingAction(false);
          }
        } catch (error) {
          console.error('Erro ao verificar status:', error);
          clearInterval(intervalId);
          setError('Erro ao verificar status: ' + (error.response?.data?.error || error.message));
          setLoadingAction(false);
        }
      }, 2000);
      
    } catch (err) {
      console.error('Erro ao atualizar status:', err);
      setError(err.response?.data?.error || 'Erro ao atualizar status do WhatsApp.');
      setLoadingAction(false);
    }
  };

  // Enviar mensagem direta para um número
  const handleSendDirectMessage = async (e) => {
    e.preventDefault();
    
    if (!directMessageData.phone || !directMessageData.message) {
      return setError('Telefone e mensagem são obrigatórios.');
    }
    
    try {
      setLoadingAction(true);
      setError('');
      setSuccess('');
      
      const response = await api.post('/api/whatsapp/send', {
        phone: directMessageData.phone,
        message: directMessageData.message
      });
      
      setSuccess('Mensagem enviada com sucesso!');
      setDirectMessageData({ phone: '', message: '' });
    } catch (err) {
      setError(err.response?.data?.error || 'Erro ao enviar mensagem. Verifique o número de telefone.');
    } finally {
      setLoadingAction(false);
    }
  };

  // Enviar mensagem para vários destinatários
  const handleSendBroadcast = async (e) => {
    e.preventDefault();
    
    if (!message) {
      return setError('A mensagem é obrigatória.');
    }
    
    try {
      setLoadingAction(true);
      setError('');
      setSuccess('');
      
      const response = await api.post('/api/whatsapp/broadcast', {
        message
      });
      
      setSuccess(`Broadcast enviado com sucesso para ${response.data.results.filter(r => r.status === 'success').length} membros.`);
      setMessage('');
    } catch (err) {
      setError(err.response?.data?.error || 'Erro ao enviar broadcast.');
    } finally {
      setLoadingAction(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Renderizar QR Code ou status
  const renderConnectionStatus = () => {
    if (loading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 3 }}>
          <CircularProgress />
        </Box>
      );
    }

    if (status.connected) {
      return (
        <Box sx={{ textAlign: 'center', py: 3 }}>
          <Avatar sx={{ width: 80, height: 80, mx: 'auto', mb: 2, bgcolor: 'success.main' }}>
            <WhatsApp sx={{ fontSize: 50 }} />
          </Avatar>
          <Typography variant="h6" color="success.main" gutterBottom>
            WhatsApp Conectado
          </Typography>
          <Button 
            variant="outlined" 
            color="error" 
            onClick={handleDisconnect}
            disabled={loadingAction}
            sx={{ mt: 2 }}
          >
            Desconectar
          </Button>
        </Box>
      );
    }

    if (!status.connected) {
      return (
        <Box sx={{ textAlign: 'center', py: 3 }}>
          <Typography variant="subtitle1" gutterBottom>
            Escaneie o QR Code com o WhatsApp do seu celular
          </Typography>
          <Box sx={{ bgcolor: 'white', p: 3, borderRadius: 2, display: 'inline-block', mt: 2 }}>
            {status.qrCode ? (
              <Box sx={{ position: 'relative' }}>
                <Box 
                  sx={{
                    width: 300,
                    padding: 3,
                    backgroundColor: '#25D366', // Cor do WhatsApp
                    borderRadius: 2,
                    color: 'white',
                    textAlign: 'center',
                    mb: 2
                  }}
                >
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                    QR CODE DISPONÍVEL!
                  </Typography>
                  <Typography variant="body2">
                    O QR code foi gerado e está visível no terminal do servidor.
                  </Typography>
                </Box>
                
                <Box sx={{ backgroundColor: 'white', p: 3, borderRadius: 2, mb: 2 }}>
                  <ol style={{ textAlign: 'left', paddingLeft: '20px' }}>
                    <li style={{ marginBottom: '10px' }}>Abra o WhatsApp no seu celular</li>
                    <li style={{ marginBottom: '10px' }}>Toque nos três pontos (menu) no canto superior direito</li>
                    <li style={{ marginBottom: '10px' }}>Selecione "Aparelhos conectados"</li>
                    <li style={{ marginBottom: '10px' }}>Toque em "Conectar um aparelho"</li>
                    <li style={{ marginBottom: '10px' }}>Escaneie o QR code mostrado no terminal do servidor</li>
                  </ol>
                </Box>
                <Typography variant="caption" sx={{ mt: 1, display: 'block', textAlign: 'center', color: 'text.secondary' }}>
                  Após escanear o QR code, o WhatsApp será conectado automaticamente
                </Typography>
              </Box>
            ) : (
              <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'center', 
                alignItems: 'center', 
                height: 256, 
                width: 256,
                border: '1px solid #eee',
                borderRadius: '4px'
              }}>
                <CircularProgress color="primary" />
                <Typography 
                  variant="caption" 
                  sx={{ mt: 2, textAlign: 'center', px: 2, maxWidth: '100%' }}
                >
                  {status.waiting 
                    ? "Aguardando geração do QR code... Isso pode levar alguns segundos."
                    : "Clique em 'Gerar QR Code' para iniciar a conexão"}
                </Typography>
              </Box>
            )}
          </Box>
          <Box sx={{ mt: 2 }}>
            <Button
              variant="outlined"
              onClick={refreshStatus}
              startIcon={<Refresh />}
              disabled={loadingAction}
              sx={{ mx: 1 }}
            >
              {status.qrCode ? "Atualizar QR Code" : "Gerar QR Code"}
            </Button>
          </Box>
        </Box>
      );
    }

    return (
      <Box sx={{ textAlign: 'center', py: 3 }}>
        <Avatar sx={{ width: 80, height: 80, mx: 'auto', mb: 2, bgcolor: 'grey.400' }}>
          <WhatsApp sx={{ fontSize: 50 }} />
        </Avatar>
        <Typography variant="h6" gutterBottom>
          WhatsApp Não Conectado
        </Typography>
        <Button 
          variant="contained" 
          color="primary"
          onClick={handleConnect}
          disabled={loadingAction}
          sx={{ mt: 2 }}
          startIcon={<QrCode />}
        >
          {loadingAction ? "Inicializando..." : "Iniciar Conexão"}
        </Button>
      </Box>
    );
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Gestão de WhatsApp
      </Typography>
      <Typography variant="body1" paragraph>
        Conecte-se ao WhatsApp e envie mensagens para os membros da igreja.
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      {success && (
        <Alert severity="success" sx={{ mb: 3 }}>
          {success}
        </Alert>
      )}

      <Grid container spacing={3}>
        {/* Status de Conexão */}
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <WhatsApp sx={{ fontSize: 28, color: 'primary.main', mr: 2 }} />
                <Typography variant="h5">Status da Conexão</Typography>
              </Box>
              <Divider sx={{ mb: 2 }} />
              
              {renderConnectionStatus()}
            </CardContent>
          </Card>
        </Grid>

        {/* Envio de Mensagens */}
        <Grid item xs={12} md={8}>
          <Card>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Message sx={{ fontSize: 28, color: 'primary.main', mr: 2 }} />
                <Typography variant="h5">Envio de Mensagens</Typography>
              </Box>
              <Divider sx={{ mb: 2 }} />
              
              {!status.connected ? (
                <Alert severity="info" sx={{ mb: 2 }}>
                  Conecte-se ao WhatsApp primeiro para enviar mensagens.
                </Alert>
              ) : (
                <Box sx={{ width: '100%' }}>
                  <Tabs value={tabValue} onChange={handleTabChange} centered>
                    <Tab icon={<Send />} label="Mensagem Direta" />
                    <Tab icon={<Group />} label="Broadcast" />
                  </Tabs>
                  
                  <Box sx={{ mt: 3 }}>
                    {/* Mensagem Direta */}
                    {tabValue === 0 && (
                      <Box component="form" onSubmit={handleSendDirectMessage}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextField
                              label="Número de Telefone"
                              fullWidth
                              required
                              helperText="Formato: DDD + número (ex: 11999998888)"
                              value={directMessageData.phone}
                              onChange={(e) => setDirectMessageData(prev => ({ ...prev, phone: e.target.value }))}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              label="Mensagem"
                              fullWidth
                              required
                              multiline
                              rows={4}
                              value={directMessageData.message}
                              onChange={(e) => setDirectMessageData(prev => ({ ...prev, message: e.target.value }))}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              type="submit"
                              variant="contained"
                              startIcon={<Send />}
                              disabled={loadingAction}
                            >
                              Enviar Mensagem
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                    
                    {/* Broadcast */}
                    {tabValue === 1 && (
                      <Box component="form" onSubmit={handleSendBroadcast}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <FormControl fullWidth sx={{ mb: 2 }}>
                              <InputLabel>Destinatários</InputLabel>
                              <Select
                                value={broadcastType}
                                onChange={(e) => setBroadcastType(e.target.value)}
                                label="Destinatários"
                              >
                                <MenuItem value="all">Todos os Membros</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              label="Mensagem para Broadcast"
                              fullWidth
                              required
                              multiline
                              rows={4}
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                              Esta mensagem será enviada para todos os membros com número de WhatsApp cadastrado.
                            </Typography>
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              startIcon={<Send />}
                              disabled={loadingAction}
                            >
                              Enviar Broadcast
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Lista de Membros com WhatsApp */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <PersonAdd sx={{ fontSize: 28, color: 'primary.main', mr: 2 }} />
                <Typography variant="h5">Membros com WhatsApp</Typography>
              </Box>
              <Divider sx={{ mb: 2 }} />
              
              {members.length > 0 ? (
                <List>
                  {members.slice(0, 10).map((member) => (
                    <ListItem key={member._id} divider>
                      <ListItemAvatar>
                        <Avatar>
                          <Person />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={member.name}
                        secondary={member.phone}
                      />
                      {status.connected && (
                        <Button 
                          size="small"
                          variant="outlined"
                          startIcon={<Send />}
                          onClick={() => {
                            setDirectMessageData({
                              phone: member.phone,
                              message: ''
                            });
                            setTabValue(0);
                          }}
                        >
                          Mensagem
                        </Button>
                      )}
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography variant="body1" color="text.secondary">
                  Nenhum membro com WhatsApp cadastrado.
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WhatsappDashboard;
