import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
  Divider,
  Paper,
  Grid,
  InputAdornment,
  Alert,
  CircularProgress,
  Container,
  useMediaQuery,
  Chip,
  useTheme,
  Avatar
} from '@mui/material';
import {
  AttachMoney,
  Favorite,
  CalendarMonth,
  CheckCircle,
  WhatsApp,
  Person,
  CreditCard,
  Lock
} from '@mui/icons-material';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { IMaskInput } from 'react-imask';
import api from '../../services/api';

// Inicializar Stripe
const stripePromise = loadStripe('pk_test_stripe_public_key');

// Componente para mascarar entrada de telefone
const WhatsAppInput = React.forwardRef(function TextMaskCustom(props, ref) {
  return (
    <IMaskInput
      {...props}
      mask="(00) 00000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      placeholder="(00) 00000-0000"
    />
  );
});

// Componente principal do formulário de doação
const DonationForm = ({ churchSlug, churchName }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [donationData, setDonationData] = useState({
    name: '',
    whatsapp: '',
    amount: '100',
    isRecurring: true,
    customAmount: false
  });
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const stripe = useStripe();
  const elements = useElements();
  
  // Valores de doação predefinidos
  const donationAmounts = [50, 100, 200, 500];
  
  // Lidar com a alteração do valor da doação
  const handleSetAmount = (value) => {
    setDonationData({
      ...donationData,
      amount: value.toString(),
      customAmount: false
    });
  };
  
  // Processar o pagamento
  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!stripe || !elements) {
      return;
    }
    
    // Validações
    if (!donationData.name.trim()) {
      return setError('Por favor, informe seu nome');
    }
    
    if (!donationData.whatsapp || donationData.whatsapp.length < 14) {
      return setError('Por favor, informe um número de WhatsApp válido');
    }
    
    if (!donationData.amount || parseFloat(donationData.amount) <= 0) {
      return setError('Por favor, selecione ou informe um valor válido');
    }
    
    try {
      setLoading(true);
      setError('');
      
      // Obter token do cartão
      const cardElement = elements.getElement(CardElement);
      const { error, token } = await stripe.createToken(cardElement);
      
      if (error) {
        throw new Error(error.message);
      }
      
      // Enviar ao backend
      const response = await api.post(`/api/tithes/public-donation/${churchSlug}`, {
        name: donationData.name,
        whatsapp: donationData.whatsapp.replace(/\D/g, ''),
        amount: parseFloat(donationData.amount),
        frequency: donationData.isRecurring ? 'monthly' : 'one-time',
        paymentMethod: 'credit_card',
        paymentToken: token.id
      });
      
      setSuccess(true);
    } catch (err) {
      setError(err.message || 'Erro ao processar sua contribuição. Por favor, tente novamente.');
    } finally {
      setLoading(false);
    }
  };
  
  if (success) {
    return (
      <Paper 
        elevation={3} 
        sx={{ 
          p: 4, 
          textAlign: 'center', 
          borderRadius: 4,
          background: 'linear-gradient(135deg, #f5f7fa 0%, #e4e8eb 100%)',
          maxWidth: 800,
          mx: 'auto'
        }}
      >
        <Box sx={{ mb: 3 }}>
          <Avatar 
            sx={{ 
              width: 80, 
              height: 80, 
              mx: 'auto', 
              bgcolor: 'success.main',
              boxShadow: '0 4px 14px 0 rgba(76, 175, 80, 0.4)'
            }}
          >
            <CheckCircle sx={{ fontSize: 50 }} />
          </Avatar>
        </Box>
        
        <Typography variant="h4" component="div" gutterBottom sx={{ fontWeight: 'bold', color: '#2E7D32' }}>
          Contribuição Realizada com Sucesso!
        </Typography>
        
        <Typography variant="h6" paragraph sx={{ color: '#424242' }}>
          <strong>Valor:</strong> R$ {parseFloat(donationData.amount).toFixed(2)} 
          {donationData.isRecurring && <span> mensal</span>}
        </Typography>
        
        <Typography variant="body1" paragraph sx={{ mb: 4 }}>
          Muito obrigado pela sua generosidade, <strong>{donationData.name}</strong>! 
          {donationData.isRecurring 
            ? ` Sua contribuição mensal será um apoio contínuo para ${churchName}.`
            : ` Sua contribuição é muito importante para ${churchName}.`}
        </Typography>
        
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
          <WhatsApp color="primary" sx={{ mr: 1 }} />
          <Typography variant="body2" color="text.secondary">
            Enviamos um comprovante para seu WhatsApp ({donationData.whatsapp})
          </Typography>
        </Box>
        
        <Button
          variant="outlined"
          color="primary"
          size="large"
          onClick={() => {
            setDonationData({
              name: '',
              whatsapp: '',
              amount: '100',
              isRecurring: true,
              customAmount: false
            });
            setSuccess(false);
          }}
          sx={{ mt: 2 }}
        >
          Fazer nova contribuição
        </Button>
      </Paper>
    );
  }
  
  return (
    <Box component="form" onSubmit={handleSubmit}>
      {error && (
        <Alert severity="error" sx={{ mb: 3, borderRadius: 2 }}>
          {error}
        </Alert>
      )}
      
      <Paper 
        elevation={3} 
        sx={{ 
          borderRadius: 4, 
          overflow: 'hidden',
          mb: 4 
        }}
      >
        <Box sx={{ 
          p: 3, 
          textAlign: 'center',
          background: 'linear-gradient(45deg, #3949ab 0%, #1e88e5 100%)',
          color: 'white'
        }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            Quanto você gostaria de contribuir?
          </Typography>
        </Box>
        
        <CardContent sx={{ p: 3 }}>
          <Grid container spacing={2} sx={{ mb: 3 }}>
            {donationAmounts.map((amount) => (
              <Grid item xs={6} sm={3} key={amount}>
                <Paper 
                  elevation={donationData.amount === amount.toString() ? 6 : 1}
                  sx={{
                    py: 2,
                    textAlign: 'center',
                    cursor: 'pointer',
                    border: donationData.amount === amount.toString() ? `2px solid ${theme.palette.primary.main}` : 'none',
                    borderRadius: 2,
                    transition: 'all 0.3s',
                    bgcolor: donationData.amount === amount.toString() ? 'rgba(25, 118, 210, 0.08)' : 'white',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: 3,
                      bgcolor: 'rgba(25, 118, 210, 0.04)'
                    }
                  }}
                  onClick={() => handleSetAmount(amount)}
                >
                  <Typography variant="h5" component="div" sx={{ fontWeight: donationData.amount === amount.toString() ? 'bold' : 'normal' }}>
                    R$ {amount}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
          
          <TextField
            label="Ou digite outro valor"
            fullWidth
            type="number"
            size="small"
            value={donationData.customAmount ? donationData.amount : ''}
            onChange={(e) => setDonationData({ 
              ...donationData, 
              amount: e.target.value,
              customAmount: true 
            })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  R$
                </InputAdornment>
              ),
              inputProps: { min: 1 }
            }}
            sx={{ mb: 3 }}
          />
          
          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'space-between',
              p: 2,
              borderRadius: 2,
              bgcolor: 'rgba(25, 118, 210, 0.04)',
              mb: 3
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CalendarMonth color="primary" sx={{ mr: 1 }} />
              <Typography variant="body1">Contribuição Mensal</Typography>
            </Box>
            
            <Switch 
              checked={donationData.isRecurring} 
              onChange={(e) => setDonationData({ ...donationData, isRecurring: e.target.checked })}
              color="primary"
            />
          </Box>
          
          {donationData.isRecurring && (
            <Box 
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                p: 2, 
                borderRadius: 2,
                bgcolor: 'rgba(76, 175, 80, 0.08)',
                mb: 3
              }}
            >
              <Favorite sx={{ color: '#e91e63', mr: 1 }} />
              <Typography variant="body2">
                Sua contribuição mensal é fundamental para mantermos nossos projetos de forma sustentável.
              </Typography>
            </Box>
          )}
          
          <Divider sx={{ mb: 3 }} />
          
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Nome completo"
                fullWidth
                required
                value={donationData.name}
                onChange={(e) => setDonationData({ ...donationData, name: e.target.value })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Person color="action" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="WhatsApp"
                fullWidth
                required
                value={donationData.whatsapp}
                onChange={(e) => setDonationData({ ...donationData, whatsapp: e.target.value })}
                InputProps={{
                  inputComponent: WhatsAppInput,
                  startAdornment: (
                    <InputAdornment position="start">
                      <WhatsApp color="action" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          
          <Box sx={{ mt: 3 }}>
            <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
              <CreditCard sx={{ mr: 1 }} /> Dados do Cartão
            </Typography>
            
            <Box 
              sx={{ 
                border: '1px solid #e0e0e0', 
                borderRadius: 2, 
                p: 2, 
                mb: 3,
                '&:focus-within': {
                  borderColor: theme.palette.primary.main,
                  boxShadow: `0 0 0 2px ${theme.palette.primary.light}30`
                }
              }}
            >
              <CardElement 
                options={{
                  style: {
                    base: {
                      fontSize: '16px',
                      color: '#424770',
                      '::placeholder': {
                        color: '#aab7c4',
                      },
                      iconColor: theme.palette.primary.main
                    },
                    invalid: {
                      color: theme.palette.error.main,
                      iconColor: theme.palette.error.main
                    },
                  },
                  hidePostalCode: true
                }}
              />
            </Box>
            
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3, color: 'text.secondary' }}>
              <Lock fontSize="small" sx={{ mr: 1 }} />
              <Typography variant="caption">
                Pagamentos seguros processados por Stripe. Seus dados estão protegidos.
              </Typography>
            </Box>
            
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<AttachMoney />}
              disabled={loading || !stripe}
              fullWidth
              type="submit"
              sx={{ 
                py: 1.5, 
                borderRadius: 2,
                background: 'linear-gradient(45deg, #2E7D32 30%, #4CAF50 90%)',
                fontSize: '1.1rem',
                fontWeight: 'bold',
                '&:hover': {
                  background: 'linear-gradient(45deg, #1B5E20 30%, #388E3C 90%)',
                }
              }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                `Contribuir ${donationData.amount ? `R$ ${parseFloat(donationData.amount).toFixed(2)}` : ''} ${donationData.isRecurring ? 'mensalmente' : ''}`
              )}
            </Button>
          </Box>
        </CardContent>
      </Paper>
      
      <Box sx={{ textAlign: 'center', color: 'text.secondary', mt: 3, mb: 5 }}>
        <Typography variant="body2">
          Ao contribuir, você aceita os termos de uso e concorda em receber confirmações por WhatsApp.
        </Typography>
      </Box>
    </Box>
  );
};

// Componente principal da página
const PublicDonation = () => {
  const theme = useTheme();
  const { churchSlug } = useParams();
  const [churchData, setChurchData] = useState({
    name: '',
    loading: true,
    error: false
  });
  
  // Carregar dados da igreja
  useEffect(() => {
    const fetchChurchData = async () => {
      try {
        const response = await api.get(`/api/churches/${churchSlug}`);
        if (response.data && response.data.church) {
          setChurchData({
            name: response.data.church.name,
            loading: false,
            error: false
          });
        } else {
          throw new Error('Dados da igreja não encontrados');
        }
      } catch (error) {
        console.error('Erro ao carregar dados da igreja:', error);
        setChurchData({
          name: '',
          loading: false,
          error: true
        });
      }
    };
    
    fetchChurchData();
  }, [churchSlug]);
  
  if (churchData.loading) {
    return (
      <Container maxWidth="md" sx={{ textAlign: 'center', py: 10 }}>
        <CircularProgress size={60} />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Carregando informações de doação...
        </Typography>
      </Container>
    );
  }
  
  if (churchData.error) {
    return (
      <Container maxWidth="md" sx={{ textAlign: 'center', py: 10 }}>
        <Alert severity="error" sx={{ maxWidth: 500, mx: 'auto', mb: 3 }}>
          Igreja não encontrada. Por favor, verifique o link de doação.
        </Alert>
        <Button variant="contained" color="primary" href="/">
          Voltar para a página inicial
        </Button>
      </Container>
    );
  }
  
  return (
    <Container maxWidth="md">
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <Typography 
          variant="h3" 
          component="h1" 
          gutterBottom
          sx={{ 
            fontWeight: 'bold',
            background: 'linear-gradient(45deg, #1976D2 30%, #2196F3 90%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            mb: 2
          }}
        >
          Apoie {churchData.name}
        </Typography>
        
        <Typography 
          variant="h6" 
          sx={{ 
            maxWidth: 600, 
            mx: 'auto', 
            mb: 4,
            color: theme.palette.text.secondary 
          }}
        >
          Sua generosidade transforma vidas. Contribua de forma rápida e segura.
        </Typography>
      </Box>
      
      <Elements stripe={stripePromise}>
        <DonationForm churchSlug={churchSlug} churchName={churchData.name} />
      </Elements>
      
      {/* Depoimentos e impacto - aumenta credibilidade */}
      <Box sx={{ mt: 6, mb: 4 }}>
        <Typography variant="h5" component="h2" textAlign="center" gutterBottom sx={{ fontWeight: 'bold' }}>
          O Impacto da Sua Contribuição
        </Typography>
        
        <Grid container spacing={3} sx={{ mt: 2 }}>
          <Grid item xs={12} md={4}>
            <Paper elevation={2} sx={{ p: 3, height: '100%', borderRadius: 3 }}>
              <Typography variant="h6" gutterBottom color="primary" sx={{ fontWeight: 'bold' }}>
                Apoio a Famílias
              </Typography>
              <Typography variant="body2">
                Suas contribuições ajudam a fornecer alimentos, roupas e assistência 
                para dezenas de famílias em situação de vulnerabilidade.
              </Typography>
            </Paper>
          </Grid>
          
          <Grid item xs={12} md={4}>
            <Paper elevation={2} sx={{ p: 3, height: '100%', borderRadius: 3 }}>
              <Typography variant="h6" gutterBottom color="primary" sx={{ fontWeight: 'bold' }}>
                Educação
              </Typography>
              <Typography variant="body2">
                Mantemos programas educacionais para crianças e jovens, 
                oferecendo reforço escolar e formação profissional.
              </Typography>
            </Paper>
          </Grid>
          
          <Grid item xs={12} md={4}>
            <Paper elevation={2} sx={{ p: 3, height: '100%', borderRadius: 3 }}>
              <Typography variant="h6" gutterBottom color="primary" sx={{ fontWeight: 'bold' }}>
                Infraestrutura
              </Typography>
              <Typography variant="body2">
                Manutenção das instalações para proporcionar um ambiente 
                adequado para cultos, estudos e atividades comunitárias.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      
      {/* FAQs curtos */}
      <Box sx={{ mt: 4, mb: 6 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
              É seguro doar online?
            </Typography>
            <Typography variant="body2" paragraph>
              Sim! Utilizamos o Stripe, uma das plataformas mais seguras do mundo 
              para processamento de pagamentos, com criptografia de ponta a ponta.
            </Typography>
          </Grid>
          
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
              Como cancelar uma contribuição recorrente?
            </Typography>
            <Typography variant="body2" paragraph>
              Entre em contato conosco pelo WhatsApp ou email que forneceremos 
              e faremos o cancelamento imediato, sem burocracia.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default PublicDonation;