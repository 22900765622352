import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import api from '../../services/api';
import {
  Avatar, Button, Container, Typography, Box, Alert, CircularProgress
} from '@mui/material';
import { AdminPanelSettings as AdminIcon } from '@mui/icons-material';

const AdminSetup = () => {
  const { churchSlug } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    // Verificar se o usuário está logado
    if (!currentUser) {
      console.log('Usuário não autenticado, continuando com setup');
      // Vamos continuar mesmo sem usuário para tentar usar o token da URL
    }

    console.log('AdminSetup: iniciando configuração para igreja:', churchSlug);
    console.log('AdminSetup: usuário atual:', currentUser);

    // Configurar usuário como admin da igreja
    const setupAdmin = async () => {
      try {
        setLoading(true);
        console.log('Chamando API para configurar admin...');
        
        // Se o usuário já estiver autenticado, use seu ID
        const userId = currentUser?.id;
        
        const response = await api.post(`/api/churches/${churchSlug}/setup-admin`, {
          userId: userId
        });
        
        console.log('Resposta da configuração de admin:', response.data);
        setSuccess(true);
        
        // Após 3 segundos, redirecionar para o dashboard
        setTimeout(() => {
          console.log('Redirecionando para o dashboard administrativo');
          navigate('/admin');
        }, 3000);
      } catch (err) {
        console.error('Erro ao configurar admin:', err);
        setError(err.response?.data?.error || 'Ocorreu um erro ao configurar seu acesso administrativo.');
      } finally {
        setLoading(false);
      }
    };

    setupAdmin();
  }, [churchSlug, currentUser, navigate]);

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'primary.main', width: 56, height: 56 }}>
          <AdminIcon fontSize="large" />
        </Avatar>
        <Typography component="h1" variant="h4" gutterBottom>
          Configuração de Administrador
        </Typography>
        
        {loading ? (
          <Box sx={{ mt: 4, textAlign: 'center' }}>
            <CircularProgress />
            <Typography variant="body1" sx={{ mt: 2 }}>
              Configurando sua conta de administrador...
            </Typography>
          </Box>
        ) : success ? (
          <Box sx={{ mt: 4 }}>
            <Alert severity="success" sx={{ mb: 2 }}>
              Configuração concluída com sucesso!
            </Alert>
            <Typography variant="body1">
              Você agora é o administrador da igreja. Redirecionando para o painel administrativo...
            </Typography>
          </Box>
        ) : (
          <Box sx={{ mt: 4 }}>
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/admin')}
            >
              Ir para o Painel Administrativo
            </Button>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default AdminSetup;