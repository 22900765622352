import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import {
  Typography, Box, Grid, Paper, Button, Divider, List, ListItem,
  ListItemText, CircularProgress, Card, CardContent, Avatar,
  Chip, Stack, IconButton, Tooltip, useTheme, useMediaQuery,
  LinearProgress, TableContainer, Table, TableHead, TableBody,
  TableRow, TableCell, Tabs, Tab, Badge
} from '@mui/material';
import {
  People, AttachMoney, Event, Message, WhatsApp, Person,
  TrendingUp, Add, ArrowForward, CalendarMonth, CreditCard,
  MoreVert, Delete, Edit, Visibility, CheckCircle, PlusOne,
  Refresh, Analytics, AccessTime, LocalAtm, PeopleAlt, EventNote,
  Settings
} from '@mui/icons-material';
import api from '../../services/api';
import { PieChart, Pie, ResponsiveContainer, Cell, BarChart, Bar, XAxis, YAxis, Tooltip as RechartsTooltip } from 'recharts';

// Card estatístico com animação de contagem e ícone
export const StatCard = ({ title, value, icon, color, linkTo, secondaryText, percentChange }) => {
  const theme = useTheme();
  return (
    <Card 
      elevation={0}
      sx={{ 
        height: '100%',
        borderRadius: 3,
        position: 'relative',
        overflow: 'hidden',
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: '0 8px 20px rgba(0,0,0,0.09)'
        }
      }}
    >
      {/* Barra colorida decorativa */}
      <Box sx={{ height: 5, bgcolor: color, width: '100%' }} />
      
      <CardContent sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="subtitle1" color="text.secondary" fontWeight="medium">
            {title}
          </Typography>
          <Avatar sx={{ bgcolor: `${color}20`, color: color }}>
            {icon}
          </Avatar>
        </Box>
        
        <Typography variant="h4" sx={{ mb: 1, fontWeight: 'bold' }}>
          {value}
        </Typography>
        
        {secondaryText && (
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Typography variant="body2" color="text.secondary">
              {secondaryText}
            </Typography>
            {percentChange && (
              <Chip 
                size="small" 
                label={`${percentChange > 0 ? '+' : ''}${percentChange}%`}
                color={percentChange >= 0 ? "success" : "error"}
                icon={percentChange >= 0 ? <TrendingUp fontSize="small" /> : <TrendingUp fontSize="small" style={{ transform: 'rotate(180deg)' }} />}
                sx={{ ml: 1, height: 20, '& .MuiChip-label': { px: 1, fontSize: '0.7rem' } }}
              />
            )}
          </Box>
        )}
        
        {linkTo && (
          <Button 
            component={RouterLink} 
            to={linkTo} 
            size="small"
            endIcon={<ArrowForward />}
            sx={{ 
              mt: 1, 
              textTransform: 'none',
              fontWeight: 'medium',
              color: color,
              '&:hover': {
                bgcolor: `${color}10`
              }
            }}
          >
            Ver detalhes
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

// Componente de gráfico de dízimos (rosca)
const TithesPieChart = ({ data }) => {
  const COLORS = ['#4caf50', '#ff9800', '#f44336', '#2196f3'];
  
  const chartData = [
    { name: 'Mensal', value: data.monthly || 0 },
    { name: 'Único', value: data.single || 0 }
  ];
  
  return (
    <Box sx={{ width: '100%', height: 200 }}>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={chartData}
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={80}
            paddingAngle={2}
            dataKey="value"
            label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
          >
            {chartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <RechartsTooltip formatter={(value) => `R$ ${value.toFixed(2)}`} />
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
};

// Componente de gráfico de tendência de membros (barras)
const MembersTrendChart = ({ data }) => {
  return (
    <Box sx={{ width: '100%', height: 200 }}>
      <ResponsiveContainer>
        <BarChart data={data}>
          <XAxis dataKey="month" axisLine={false} tickLine={false} />
          <YAxis hide />
          <RechartsTooltip formatter={(value) => value} />
          <Bar dataKey="count" fill="#3f51b5" radius={[4, 4, 0, 0]} />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

// Componente principal do Dashboard
const Dashboard = () => {
  const { currentUser } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  const [stats, setStats] = useState({
    members: { total: 0, active: 0, new: 0, percentChange: 0 },
    tithes: { count: 0, total: 0, monthly: 0, single: 0, percentChange: 0 },
    events: { upcoming: 0, total: 0, percentChange: 0 },
    messages: { total: 0, read: 0, percentChange: 0 }
  });
  
  const [recentMembers, setRecentMembers] = useState([]);
  const [recentTithes, setRecentTithes] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [membersTrend, setMembersTrend] = useState([]);
  const [monthlyTithes, setMonthlyTithes] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Buscar dados reais
        const [membersResponse, tithesResponse, eventsResponse, messagesResponse] = await Promise.all([
          api.get('/api/members'),
          api.get('/api/tithes'),
          api.get('/api/events'),
          api.get('/api/messages')
        ]);
        
        const members = membersResponse.data;
        const tithes = tithesResponse.data;
        const events = eventsResponse.data;
        const messages = messagesResponse.data;
        
        // Calcular estatísticas reais
        const now = new Date();
        const thirtyDaysAgo = new Date(now.getTime() - (30 * 24 * 60 * 60 * 1000));
        
        // Calcular novos membros nos últimos 30 dias
        const newMembers = members.filter(m => new Date(m.createdAt) > thirtyDaysAgo).length;
        
        // Calcular membros ativos (que fizeram login nos últimos 30 dias)
        const activeMembers = members.filter(m => m.lastLogin && new Date(m.lastLogin) > thirtyDaysAgo).length;
        
        // Calcular taxa de retenção
        const retentionRate = members.length > 0 ? (activeMembers / members.length) * 100 : 0;
        
        // Calcular tendência de membros por mês
        const membersByMonth = {};
        members.forEach(member => {
          const date = new Date(member.createdAt);
          const monthYear = `${date.getMonth() + 1}/${date.getFullYear()}`;
          membersByMonth[monthYear] = (membersByMonth[monthYear] || 0) + 1;
        });
        
        // Converter para formato do gráfico
        const trendData = Object.entries(membersByMonth)
          .map(([monthYear, count]) => {
            const [month, year] = monthYear.split('/');
            return {
              month: `${month}/${year}`,
              count
            };
          })
          .sort((a, b) => {
            const [monthA, yearA] = a.month.split('/');
            const [monthB, yearB] = b.month.split('/');
            return new Date(yearA, monthA - 1) - new Date(yearB, monthB - 1);
          });
        
        setMembersTrend(trendData);
        
        // Calcular total de dízimos
        const titheTotal = tithes.reduce((sum, tithe) => sum + tithe.amount, 0);
        
        // Calcular dízimos mensais vs. únicos
        const monthlyTithesList = tithes.filter(t => t.frequency === 'monthly');
        const singleTithes = tithes.filter(t => t.frequency === 'one-time');
        const monthlyTotal = monthlyTithesList.reduce((sum, tithe) => sum + tithe.amount, 0);
        const singleTotal = singleTithes.reduce((sum, tithe) => sum + tithe.amount, 0);
        
        setMonthlyTithes(monthlyTithesList);
        
        // Calcular eventos futuros
        const futureEvents = events.filter(event => new Date(event.startDate) > now);
        
        setStats({
          members: { 
            total: members.length, 
            active: activeMembers,
            new: newMembers,
            percentChange: members.length > 0 ? ((newMembers / members.length) * 100) : 0
          },
          tithes: { 
            count: tithes.length, 
            total: titheTotal, 
            monthly: monthlyTotal,
            single: singleTotal,
            percentChange: tithes.length > 0 ? ((monthlyTithesList.length / tithes.length) * 100) : 0
          },
          events: { 
            upcoming: futureEvents.length, 
            total: events.length,
            percentChange: events.length > 0 ? ((futureEvents.length / events.length) * 100) : 0
          },
          messages: { 
            total: messages.length, 
            read: messages.filter(m => m.read).length,
            percentChange: messages.length > 0 ? ((messages.filter(m => m.read).length / messages.length) * 100) : 0
          }
        });
        
        // Ordenar dados por data
        const sortedMembers = [...members].sort((a, b) => 
          new Date(b.createdAt) - new Date(a.createdAt)
        );
        setRecentMembers(sortedMembers.slice(0, 5));
        
        const sortedTithes = [...tithes].sort((a, b) => 
          new Date(b.createdAt) - new Date(a.createdAt)
        );
        setRecentTithes(sortedTithes.slice(0, 5));
        
        const sortedEvents = [...futureEvents].sort((a, b) => 
          new Date(a.startDate) - new Date(b.startDate)
        );
        setUpcomingEvents(sortedEvents.slice(0, 5));
      } catch (error) {
        console.error('Erro ao carregar dados do dashboard:', error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchData();
  }, []);

  // Mudar aba para dados recentes
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 8 }}>
        <CircularProgress size={60} />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Carregando dados...
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      {/* Cabeçalho do Dashboard */}
      <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
        <Box>
          <Typography variant="h4" gutterBottom fontWeight="bold">
            Dashboard
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ maxWidth: 600 }}>
            Bem-vindo, {currentUser.name}! Aqui está um resumo geral das atividades da igreja.
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 1, mt: { xs: 2, sm: 0 } }}>
          <Button 
            variant="outlined" 
            startIcon={<Refresh />}
            onClick={() => window.location.reload()}
            size="small"
          >
            Atualizar
          </Button>
          <Button 
            variant="contained" 
            startIcon={<Analytics />}
            color="primary"
            size="small"
          >
            Relatórios
          </Button>
        </Box>
      </Box>

      {/* Cards de Estatísticas */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard 
            title="Membros" 
            value={stats.members.total} 
            icon={<People />}
            color="#3f51b5"
            linkTo="/admin/membros"
            secondaryText={`${stats.members.new} novos nesta semana`}
            percentChange={stats.members.percentChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard 
            title="Total de Dízimos" 
            value={`R$ ${stats.tithes.total.toFixed(2)}`}
            icon={<AttachMoney />}
            color="#4caf50"
            linkTo="/admin/dizimos"
            secondaryText={`${stats.tithes.count} contribuições`}
            percentChange={stats.tithes.percentChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard 
            title="Eventos" 
            value={stats.events.total}
            icon={<Event />}
            color="#ff9800"
            linkTo="/admin/eventos"
            secondaryText={`${stats.events.upcoming} eventos futuros`}
            percentChange={stats.events.percentChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard 
            title="Mensagens" 
            value={stats.messages.total}
            icon={<Message />}
            color="#e91e63"
            linkTo="/admin/mensagens"
            secondaryText={`${stats.messages.read} lidas`}
            percentChange={stats.messages.percentChange}
          />
        </Grid>
      </Grid>

      {/* Gráficos e Análises */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        {/* Análise de Dízimos */}
        <Grid item xs={12} md={6}>
          <Card elevation={0} sx={{ height: '100%', borderRadius: 3 }}>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6" fontWeight="medium">
                  Análise de Dízimos
                </Typography>
                <Tooltip title="Ver relatório completo">
                  <IconButton size="small" component={RouterLink} to="/admin/dizimos">
                    <ArrowForward fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
              
              <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2 }}>
                <Box sx={{ flex: 1 }}>
                  <TithesPieChart data={{ monthly: stats.tithes.monthly, single: stats.tithes.single }} />
                </Box>
                
                <Box sx={{ flex: 1 }}>
                  <List dense>
                    <ListItem>
                      <LocalAtm sx={{ mr: 1, color: '#4caf50' }} />
                      <ListItemText 
                        primary="Contribuições Mensais"
                        secondary={`R$ ${stats.tithes.monthly.toFixed(2)}`}
                      />
                    </ListItem>
                    <ListItem>
                      <CreditCard sx={{ mr: 1, color: '#ff9800' }} />
                      <ListItemText 
                        primary="Contribuições Únicas"
                        secondary={`R$ ${stats.tithes.single.toFixed(2)}`} 
                      />
                    </ListItem>
                    <ListItem>
                      <PeopleAlt sx={{ mr: 1, color: '#3f51b5' }} />
                      <ListItemText 
                        primary="Contribuintes Ativos"
                        secondary={`${monthlyTithes.length} membros`} 
                      />
                    </ListItem>
                  </List>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        
        {/* Tendência de Membros */}
        <Grid item xs={12} md={6}>
          <Card elevation={0} sx={{ height: '100%', borderRadius: 3 }}>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6" fontWeight="medium">
                  Crescimento de Membros
                </Typography>
                <Tooltip title="Ver relatório completo">
                  <IconButton size="small" component={RouterLink} to="/admin/membros">
                    <ArrowForward fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
              
              <MembersTrendChart data={membersTrend} />
              
              <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 1 }}>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="body2" color="text.secondary">Ativos</Typography>
                  <Typography variant="h6" color="primary.main" fontWeight="bold">
                    {stats.members.active}
                  </Typography>
                </Box>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="body2" color="text.secondary">Novos (30 dias)</Typography>
                  <Typography variant="h6" color="success.main" fontWeight="bold">
                    {stats.members.new}
                  </Typography>
                </Box>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="body2" color="text.secondary">Taxa de Retenção</Typography>
                  <Typography variant="h6" color="info.main" fontWeight="bold">
                    {stats.members.total > 0 ? Math.round((stats.members.active / stats.members.total) * 100) : 0}%
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      
      {/* Dados Recentes (Tabs) */}
      <Card elevation={0} sx={{ borderRadius: 3 }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{ px: 2, borderBottom: 1, borderColor: 'divider' }}
        >
          <Tab 
            label="Próximos Eventos" 
            icon={<EventNote />} 
            iconPosition="start"
            sx={{ textTransform: 'none', fontWeight: 'medium' }}
          />
          <Tab 
            label="Membros Recentes" 
            icon={<Person />} 
            iconPosition="start"
            sx={{ textTransform: 'none', fontWeight: 'medium' }}
          />
          <Tab 
            label="Dízimos Recentes" 
            icon={<AttachMoney />} 
            iconPosition="start"
            sx={{ textTransform: 'none', fontWeight: 'medium' }}
          />
        </Tabs>
        
        <CardContent sx={{ p: { xs: 2, md: 3 } }}>
          {/* Tab 1: Próximos Eventos */}
          {tabValue === 0 && (
            <>
              {upcomingEvents.length > 0 ? (
                <Grid container spacing={2}>
                  {upcomingEvents.map((event) => (
                    <Grid item xs={12} sm={6} md={4} key={event._id}>
                      <Paper 
                        elevation={0} 
                        sx={{ 
                          p: 2, 
                          borderRadius: 2, 
                          border: '1px solid',
                          borderColor: 'divider',
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          position: 'relative'
                        }}
                      >
                        <Box sx={{ position: 'absolute', top: 12, right: 12 }}>
                          <IconButton size="small">
                            <MoreVert fontSize="small" />
                          </IconButton>
                        </Box>
                        
                        <Box sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
                          <CalendarMonth sx={{ color: 'primary.main', mr: 1 }} />
                          <Typography variant="subtitle1" fontWeight="medium">
                            {event.title}
                          </Typography>
                        </Box>
                        
                        <Typography 
                          variant="body2" 
                          color="text.secondary" 
                          sx={{ mb: 2, flex: 1 }}
                        >
                          {event.description?.slice(0, 60)}
                          {event.description?.length > 60 ? '...' : ''}
                        </Typography>
                        
                        <Box>
                          <Chip 
                            label={new Date(event.startDate).toLocaleDateString()} 
                            size="small" 
                            color="primary" 
                            variant="outlined"
                            icon={<AccessTime fontSize="small" />}
                            sx={{ mr: 1, mb: 1 }}
                          />
                          <Chip 
                            label={event.location} 
                            size="small" 
                            color="secondary" 
                            variant="outlined"
                            sx={{ mb: 1 }}
                          />
                        </Box>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Box sx={{ textAlign: 'center', py: 4 }}>
                  <Typography variant="body1" color="text.secondary">
                    Não há eventos programados para os próximos dias.
                  </Typography>
                  <Button 
                    variant="contained" 
                    color="primary" 
                    startIcon={<Add />}
                    component={RouterLink}
                    to="/admin/eventos"
                    sx={{ mt: 2 }}
                  >
                    Criar Evento
                  </Button>
                </Box>
              )}
            </>
          )}
          
          {/* Tab 2: Membros Recentes */}
          {tabValue === 1 && (
            <>
              {recentMembers.length > 0 ? (
                <TableContainer sx={{ overflowX: 'auto' }}>
                  <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Nome</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Telefone</TableCell>
                        <TableCell>Data Cadastro</TableCell>
                        <TableCell align="right">Ações</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {recentMembers.map((member) => (
                        <TableRow key={member._id}>
                          <TableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Avatar 
                                sx={{ 
                                  mr: 2, 
                                  bgcolor: 'primary.light',
                                  width: 36,
                                  height: 36
                                }}
                              >
                                {member.name?.charAt(0)}
                              </Avatar>
                              <Typography variant="body2">
                                {member.name}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell>{member.email}</TableCell>
                          <TableCell>{member.phone || '-'}</TableCell>
                          <TableCell>
                            {new Date(member.createdAt).toLocaleDateString()}
                          </TableCell>
                          <TableCell align="right">
                            <Tooltip title="Visualizar">
                              <IconButton size="small">
                                <Visibility fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Editar">
                              <IconButton size="small">
                                <Edit fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Box sx={{ textAlign: 'center', py: 4 }}>
                  <Typography variant="body1" color="text.secondary">
                    Nenhum membro cadastrado recentemente.
                  </Typography>
                  <Button 
                    variant="contained" 
                    color="primary" 
                    startIcon={<Add />}
                    component={RouterLink}
                    to="/admin/membros"
                    sx={{ mt: 2 }}
                  >
                    Adicionar Membro
                  </Button>
                </Box>
              )}
            </>
          )}
          
          {/* Tab 3: Dízimos Recentes */}
          {tabValue === 2 && (
            <>
              {recentTithes.length > 0 ? (
                <TableContainer sx={{ overflowX: 'auto' }}>
                  <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Membro</TableCell>
                        <TableCell>Valor</TableCell>
                        <TableCell>Tipo</TableCell>
                        <TableCell>Data</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell align="right">Ações</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {recentTithes.map((tithe) => (
                        <TableRow key={tithe._id}>
                          <TableCell>
                            {tithe.member?.name || 'Membro'}
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="body2"
                              fontWeight="medium"
                              color="success.main"
                            >
                              R$ {tithe.amount.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Chip 
                              size="small"
                              label={tithe.frequency === 'monthly' ? 'Mensal' : 'Único'}
                              color={tithe.frequency === 'monthly' ? 'info' : 'default'}
                              variant="outlined"
                            />
                          </TableCell>
                          <TableCell>
                            {new Date(tithe.createdAt).toLocaleDateString()}
                          </TableCell>
                          <TableCell>
                            <Chip 
                              size="small"
                              label={tithe.status === 'active' ? 'Ativo' : tithe.status}
                              color={tithe.status === 'active' ? 'success' : 'default'}
                              icon={tithe.status === 'active' ? <CheckCircle fontSize="small" /> : undefined}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <Tooltip title="Visualizar">
                              <IconButton size="small">
                                <Visibility fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Box sx={{ textAlign: 'center', py: 4 }}>
                  <Typography variant="body1" color="text.secondary">
                    Nenhum dízimo registrado recentemente.
                  </Typography>
                </Box>
              )}
            </>
          )}
          
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button 
              variant="outlined"
              endIcon={<ArrowForward />}
              component={RouterLink}
              to={
                tabValue === 0 ? '/admin/eventos' : 
                tabValue === 1 ? '/admin/membros' : 
                '/admin/dizimos'
              }
            >
              Ver todos
            </Button>
          </Box>
        </CardContent>
      </Card>
      
      {/* Ações Rápidas */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" gutterBottom>
          Ações Rápidas
        </Typography>
        
        <Grid container spacing={2}>
          <Grid item xs={6} sm={4} md={2}>
            <Button
              variant="outlined"
              fullWidth
              component={RouterLink}
              to="/admin/membros"
              startIcon={<Add />}
              sx={{ py: 1.5, borderRadius: 2 }}
            >
              Novo Membro
            </Button>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Button
              variant="outlined"
              fullWidth
              component={RouterLink}
              to="/admin/eventos"
              startIcon={<Event />}
              sx={{ py: 1.5, borderRadius: 2 }}
            >
              Novo Evento
            </Button>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Button
              variant="outlined"
              fullWidth
              component={RouterLink}
              to="/admin/mensagens"
              startIcon={<Message />}
              sx={{ py: 1.5, borderRadius: 2 }}
            >
              Nova Mensagem
            </Button>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Button
              variant="outlined"
              fullWidth
              component={RouterLink}
              to="/admin/whatsapp"
              startIcon={<WhatsApp />}
              sx={{ py: 1.5, borderRadius: 2 }}
            >
              WhatsApp
            </Button>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Button
              variant="outlined"
              fullWidth
              color="secondary"
              startIcon={<Analytics />}
              sx={{ py: 1.5, borderRadius: 2 }}
            >
              Relatórios
            </Button>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Button
              variant="outlined"
              fullWidth
              color="secondary"
              startIcon={<Settings />}
              sx={{ py: 1.5, borderRadius: 2 }}
            >
              Configurações
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Dashboard;