import React, { createContext, useState, useContext, useEffect } from 'react';
import api from '../services/api';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [loading, setLoading] = useState(true);

  // Função para registrar um novo usuário
  const register = async (name, email, password, phone) => {
    try {
      const response = await api.post('/api/auth/register', {
        name,
        email,
        password,
        phone
      });
      
      const { token, user } = response.data;
      
      // Salvar token no localStorage
      localStorage.setItem('token', token);
      localStorage.setItem('currentUser', JSON.stringify(user));
      
      // Garantir que o token seja usado em todas as requisições
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      
      // Atualizar estado
      setToken(token);
      setCurrentUser(user);
      
      return user;
    } catch (error) {
      throw new Error(error.response?.data?.error || 'Erro ao cadastrar');
    }
  };

  // Função para fazer login
  const login = async (email, password) => {
    try {
      const response = await api.post('/api/auth/login', {
        email,
        password
      });
      
      const { token, user } = response.data;
      
      // Salvar token e usuário no localStorage
      localStorage.setItem('token', token);
      localStorage.setItem('currentUser', JSON.stringify(user));
      
      // Garantir que o token seja usado em todas as requisições
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      
      // Atualizar estado
      setToken(token);
      setCurrentUser(user);
      
      return user;
    } catch (error) {
      throw new Error(error.response?.data?.error || 'Credenciais inválidas');
    }
  };

  // Função para fazer logout
  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('currentUser');
    delete api.defaults.headers.common['Authorization'];
    setToken(null);
    setCurrentUser(null);
  };

  // Função para atualizar perfil
  const updateProfile = async (data) => {
    try {
      const response = await api.put('/api/auth/profile', data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      // Recarregar informações do usuário
      loadUser();
      
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.error || 'Erro ao atualizar perfil');
    }
  };

  // Função para carregar informações do usuário atual
  const loadUser = async () => {
    if (!token) {
      setLoading(false);
      return;
    }
    
    try {
      // Garantir que o token esteja configurado em todas as solicitações
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      
      const response = await api.get('/api/auth/me');
      
      // Verificar se temos dados de usuário válidos
      if (response.data && response.data.user) {
        const userData = response.data.user;
        console.log('Dados do usuário carregados:', userData);
        
        // Atualizar no localStorage também
        localStorage.setItem('currentUser', JSON.stringify(userData));
        
        // Atualizar no state
        setCurrentUser(userData);
      } else {
        throw new Error('Dados de usuário inválidos');
      }
    } catch (error) {
      console.error('Erro ao carregar usuário:', error);
      localStorage.removeItem('token');
      setToken(null);
      setCurrentUser(null);
    } finally {
      setLoading(false);
    }
  };

  // Configurar interceptor para incluir token em todas as requisições
  useEffect(() => {
    if (token) {
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete api.defaults.headers.common['Authorization'];
    }
  }, [token]);

  // Carregar usuário ao iniciar e debugar o processo
  useEffect(() => {
    console.log('AuthContext initializing - token:', token);
    
    // Verificar se há um usuário no localStorage
    const savedUser = localStorage.getItem('currentUser');
    console.log('Saved user from localStorage:', savedUser ? 'exists' : 'does not exist');
    
    if (savedUser && token) {
      try {
        const parsedUser = JSON.parse(savedUser);
        console.log('Parsed user from localStorage:', parsedUser);
        setCurrentUser(parsedUser);
        setLoading(false); // Importante: defina loading como false depois de carregar o usuário
      } catch (e) {
        console.error('Erro ao carregar usuário do localStorage:', e);
        loadUser();
      }
    } else {
      console.log('No user in localStorage or no token, calling loadUser()');
      loadUser();
    }
  }, []);

  const value = {
    currentUser,
    token,
    loading,
    register,
    login,
    logout,
    updateProfile
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}
