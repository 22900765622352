import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import {
  Typography, Box, Paper, TextField, Button, Grid, FormControl,
  InputLabel, Select, MenuItem, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Divider, Card, CardContent, Alert, CircularProgress
} from '@mui/material';
import { AttachMoney } from '@mui/icons-material';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import api from '../../services/api';

// Carrega Stripe (na produção, usaria chave pública real)
const stripePromise = loadStripe('pk_test_stripe_public_key');

// Componente de formulário de pagamento
const PaymentForm = ({ onPaymentSuccess }) => {
  const [amount, setAmount] = useState('');
  const [frequency, setFrequency] = useState('monthly');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  
  const stripe = useStripe();
  const elements = useElements();
  const { currentUser } = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!stripe || !elements) {
      return;
    }
    
    // Validar valor
    if (!amount || parseFloat(amount) <= 0) {
      return setError('Por favor, informe um valor válido.');
    }
    
    try {
      setLoading(true);
      setError('');
      
      // Criar token do cartão
      const cardElement = elements.getElement(CardElement);
      const { error, token } = await stripe.createToken(cardElement);
      
      if (error) {
        throw new Error(error.message);
      }
      
      // Enviar para o backend
      const response = await api.post('/api/tithes', {
        amount: parseFloat(amount),
        frequency,
        paymentMethod: 'credit_card',
        paymentToken: token.id
      });
      
      // Limpar formulário e mostrar sucesso
      setAmount('');
      cardElement.clear();
      setSuccess(true);
      onPaymentSuccess(); // Atualizar lista de dízimos
      
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    } catch (err) {
      setError(err.message || 'Erro ao processar pagamento. Tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      
      {success && (
        <Alert severity="success" sx={{ mb: 2 }}>
          Dízimo processado com sucesso! Obrigado pela sua contribuição.
        </Alert>
      )}
      
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Valor (R$)"
            fullWidth
            required
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            InputProps={{ inputProps: { min: 1, step: 'any' } }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Frequência</InputLabel>
            <Select
              value={frequency}
              onChange={(e) => setFrequency(e.target.value)}
              label="Frequência"
            >
              <MenuItem value="monthly">Mensal (Recorrente)</MenuItem>
              <MenuItem value="one-time">Uma única vez</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            Dados do Cartão
          </Typography>
          <Box sx={{ border: '1px solid #ccc', borderRadius: 1, p: 2, mb: 2 }}>
            <CardElement 
              options={{
                style: {
                  base: {
                    fontSize: '16px',
                    color: '#424770',
                    '::placeholder': {
                      color: '#aab7c4',
                    },
                  },
                  invalid: {
                    color: '#9e2146',
                  },
                },
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Button 
            type="submit" 
            variant="contained" 
            color="primary"
            disabled={loading || !stripe}
            startIcon={<AttachMoney />}
          >
            {loading ? 'Processando...' : `Doar ${amount ? `R$ ${amount}` : ''}`}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

const Tithe = () => {
  const { currentUser } = useAuth();
  const [tithes, setTithes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [churchSlug, setChurchSlug] = useState('');

  const fetchTithes = async () => {
    try {
      setLoading(true);
      const response = await api.get('/api/tithes/my-tithes');
      setTithes(response.data);
    } catch (err) {
      setError('Erro ao carregar histórico de dízimos.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Obter o slug da igreja
    const fetchChurchSlug = async () => {
      if (currentUser && currentUser.church) {
        try {
          const response = await api.get(`/api/churches`);
          const churches = response.data.churches;
          
          // Encontrar a igreja do usuário
          const church = churches.find(c => c._id === currentUser.church);
          
          if (church && church.slug) {
            setChurchSlug(church.slug);
          }
        } catch (error) {
          console.error("Erro ao buscar slug da igreja:", error);
        }
      }
    };
    
    fetchChurchSlug();
    fetchTithes();
  }, [currentUser]);

  const handleCancelSubscription = async (titheId) => {
    try {
      await api.post(`/api/tithes/${titheId}/cancel`);
      fetchTithes(); // Atualizar lista
    } catch (error) {
      setError('Erro ao cancelar assinatura. Tente novamente.');
    }
  };

  return (
    <Box>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        mb: 2
      }}>
        <Box>
          <Typography variant="h4" gutterBottom>
            Dízimo Online
          </Typography>
          <Typography variant="body1" paragraph>
            Contribua com a obra de Deus de forma segura e prática.
          </Typography>
        </Box>
        
        <Button 
          variant="contained" 
          color="primary"
          component={RouterLink}
          to={currentUser?.church ? `/doar/${churchSlug}` : '#'}
          disabled={!churchSlug}
          sx={{ 
            borderRadius: 2,
            py: 1.5,
            px: 3,
            background: 'linear-gradient(45deg, #2E7D32 30%, #4CAF50 90%)',
            fontWeight: 'bold',
            boxShadow: '0 4px 12px rgba(76, 175, 80, 0.3)',
            '&:hover': {
              background: 'linear-gradient(45deg, #1B5E20 30%, #388E3C 90%)',
              boxShadow: '0 6px 14px rgba(76, 175, 80, 0.4)',
              transform: 'translateY(-2px)'
            },
            transition: 'all 0.3s ease'
          }}
          startIcon={<AttachMoney />}
        >
          Faça uma Doação Rápida
        </Button>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card sx={{ mb: 3 }}>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Faça sua contribuição
              </Typography>
              <Divider sx={{ my: 2 }} />
              
              <Elements stripe={stripePromise}>
                <PaymentForm onPaymentSuccess={fetchTithes} />
              </Elements>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Histórico de Contribuições
              </Typography>
              <Divider sx={{ my: 2 }} />
              
              {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {error}
                </Alert>
              )}
              
              {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', my: 3 }}>
                  <CircularProgress />
                </Box>
              ) : tithes.length > 0 ? (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Data</TableCell>
                        <TableCell>Valor</TableCell>
                        <TableCell>Tipo</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Ações</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tithes.map((tithe) => (
                        <TableRow key={tithe._id}>
                          <TableCell>
                            {new Date(tithe.createdAt).toLocaleDateString()}
                          </TableCell>
                          <TableCell>R$ {tithe.amount.toFixed(2)}</TableCell>
                          <TableCell>
                            {tithe.frequency === 'monthly' ? 'Mensal' : 'Único'}
                          </TableCell>
                          <TableCell>
                            {tithe.status === 'active' ? 'Ativo' : 
                              tithe.status === 'canceled' ? 'Cancelado' : 'Falhou'}
                          </TableCell>
                          <TableCell>
                            {tithe.frequency === 'monthly' && tithe.status === 'active' && (
                              <Button 
                                size="small" 
                                color="error"
                                onClick={() => handleCancelSubscription(tithe._id)}
                              >
                                Cancelar
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography variant="body1" color="text.secondary">
                  Você ainda não tem contribuições registradas.
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Tithe;
