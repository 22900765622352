import React, { useState, useEffect } from 'react';
import {
  Typography, Box, Paper, Grid, Switch, FormControlLabel,
  Card, CardContent, Divider, Alert, CircularProgress,
  Button, Tooltip, IconButton
} from '@mui/material';
import {
  Notifications, Email, Event, AttachMoney,
  Cake, Save, Info, Settings
} from '@mui/icons-material';
import api from '../../services/api';

const CommunicationSettings = () => {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [settings, setSettings] = useState({
    birthdayMessages: false,
    noTithe45Days: false,
    eventReminder48h: false,
    noTithe60Days: false
  });

  // Efeito para carregar configurações
  useEffect(() => {
    loadSettings();
  }, []);

  // Função para carregar configurações
  const loadSettings = async () => {
    try {
      setLoading(true);
      const response = await api.get('/api/settings/communication');
      setSettings(response.data);
      setLoading(false);
    } catch (err) {
      console.error('Erro ao carregar configurações:', err);
      setError('Não foi possível carregar as configurações de comunicação.');
      setLoading(false);
    }
  };

  // Função para alterar configurações
  const handleToggle = (setting) => {
    setSettings(prev => ({
      ...prev,
      [setting]: !prev[setting]
    }));
  };

  // Função para salvar configurações
  const saveSettings = async () => {
    try {
      setSaving(true);
      setError('');
      await api.post('/api/settings/communication', settings);
      setSuccess('Configurações salvas com sucesso!');
      setSaving(false);
      
      // Limpar mensagem de sucesso após 3 segundos
      setTimeout(() => setSuccess(''), 3000);
    } catch (err) {
      setError('Erro ao salvar as configurações: ' + (err.response?.data?.error || err.message));
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Rotinas de Comunicações
      </Typography>
      <Typography variant="body1" paragraph>
        Configure as rotinas automáticas de comunicação com os membros.
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      {success && (
        <Alert severity="success" sx={{ mb: 3 }}>
          {success}
        </Alert>
      )}

      <Paper sx={{ p: 3, mb: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Notifications color="primary" sx={{ mr: 1 }} />
                  <Typography variant="h6">Configurações de Notificações Automáticas</Typography>
                </Box>
                <Typography variant="body2" color="text.secondary" paragraph>
                  Ative ou desative as rotinas automáticas de comunicação. Estas rotinas enviarão mensagens 
                  para os membros com base nos critérios selecionados.
                </Typography>
                
                <Divider sx={{ my: 2 }} />
                
                <Grid container spacing={2}>
                  {/* Mensagem de aniversário */}
                  <Grid item xs={12} md={6}>
                    <Card variant="outlined" sx={{ mb: 2, borderRadius: 2 }}>
                      <CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Cake color="secondary" sx={{ mr: 1 }} />
                            <Typography variant="subtitle1">Mensagens de Aniversário</Typography>
                          </Box>
                          <Box>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={settings.birthdayMessages}
                                  onChange={() => handleToggle('birthdayMessages')}
                                  color="primary"
                                />
                              }
                              label={settings.birthdayMessages ? "Ativado" : "Desativado"}
                            />
                          </Box>
                        </Box>
                        <Typography variant="body2" color="text.secondary">
                          Envia automaticamente mensagens de felicitações no dia do aniversário de cada membro.
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  
                  {/* Mais de 45 dias sem doação */}
                  <Grid item xs={12} md={6}>
                    <Card variant="outlined" sx={{ mb: 2, borderRadius: 2 }}>
                      <CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <AttachMoney color="error" sx={{ mr: 1 }} />
                            <Typography variant="subtitle1">Alerta de 45 Dias Sem Doação</Typography>
                          </Box>
                          <Box>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={settings.noTithe45Days}
                                  onChange={() => handleToggle('noTithe45Days')}
                                  color="primary"
                                />
                              }
                              label={settings.noTithe45Days ? "Ativado" : "Desativado"}
                            />
                          </Box>
                        </Box>
                        <Typography variant="body2" color="text.secondary">
                          Notifica membros que estão há mais de 45 dias sem fazer qualquer contribuição.
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  
                  {/* Lembrete de eventos 48h antes */}
                  <Grid item xs={12} md={6}>
                    <Card variant="outlined" sx={{ mb: 2, borderRadius: 2 }}>
                      <CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Event color="primary" sx={{ mr: 1 }} />
                            <Typography variant="subtitle1">Lembrete de Eventos (48h)</Typography>
                          </Box>
                          <Box>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={settings.eventReminder48h}
                                  onChange={() => handleToggle('eventReminder48h')}
                                  color="primary"
                                />
                              }
                              label={settings.eventReminder48h ? "Ativado" : "Desativado"}
                            />
                          </Box>
                        </Box>
                        <Typography variant="body2" color="text.secondary">
                          Envia lembretes 48 horas antes de eventos para os quais o membro está inscrito.
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  
                  {/* Mais de 60 dias sem doação */}
                  <Grid item xs={12} md={6}>
                    <Card variant="outlined" sx={{ mb: 2, borderRadius: 2 }}>
                      <CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <AttachMoney color="warning" sx={{ mr: 1 }} />
                            <Typography variant="subtitle1">Alerta de 60 Dias Sem Doação</Typography>
                          </Box>
                          <Box>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={settings.noTithe60Days}
                                  onChange={() => handleToggle('noTithe60Days')}
                                  color="primary"
                                />
                              }
                              label={settings.noTithe60Days ? "Ativado" : "Desativado"}
                            />
                          </Box>
                        </Box>
                        <Typography variant="body2" color="text.secondary">
                          Notifica membros que estão há mais de 60 dias sem fazer qualquer contribuição.
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Button 
                variant="contained" 
                color="primary" 
                startIcon={<Save />}
                onClick={saveSettings}
                disabled={saving}
              >
                {saving ? 'Salvando...' : 'Salvar Configurações'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      
      {/* Seção de informações adicionais */}
      <Paper sx={{ p: 3, bgcolor: 'rgba(0, 0, 0, 0.02)', borderRadius: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Info color="info" sx={{ mr: 1 }} />
          <Typography variant="h6">Informações Adicionais</Typography>
        </Box>
        <Typography variant="body2" paragraph>
          As mensagens automáticas são enviadas usando o sistema de WhatsApp configurado. 
          Certifique-se de que o serviço de WhatsApp esteja conectado e funcionando corretamente.
        </Typography>
        <Typography variant="body2">
          Você pode personalizar os modelos de mensagens na seção de <strong>Gestão de Mensagens</strong>.
        </Typography>
      </Paper>
    </Box>
  );
};

export default CommunicationSettings;